<script>
import { ref, reactive } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { tableColumn, noSettlement } from './tabColumn'
import commonTable from './components/commonTable.vue'

export default {
  name: 'ReportGeneration',
  components: { TabsPro, TabPanePro, commonTable },
  props: {},
  setup() {
    const componentRef = ref()
    const activeName = ref('report')
    const searchForm = reactive({
      channelId: undefined,
      channelName: undefined,
      platform: undefined,
    })

    const tabList = [
      { label: '报表生成管理', value: 'report', element: 'commonTable', header: tableColumn },
      { label: '不结算名单', value: 'noSettlement', element: 'commonTable', header: noSettlement },
    ]

    const tabClick = val => {
      activeName.value = val.name
      const index = tabList.findIndex(item => item.value === val.name)
      componentRef.value[index].search()
    }

    return {
      activeName,
      componentRef,
      tabClick,
      tabList,
      searchForm,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro
      v-model="activeName"
      style="height: 100%;"
      @tabClick="tabClick"
    >
      <tab-pane-pro
        v-for="item in tabList"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
        <component
          :is="item.element"
          ref="componentRef"
          :key="item.value"
          :header="item.header"
          :form-data="searchForm"
          :tab="item.value"
          :label="item.label"
          :active-name="activeName"
        >
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  height: calc( 100% - 50px);
}
</style>
