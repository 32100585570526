import http from '@http'

/**
 * 增值税发票tab统计
 * @param {*} params
 * @returns
 */
export function tabStatistics(params) {
  return http.get('/invoice/management/vat/tab/statistics', params)
}

/**
 * 增值税发票金额统计
 * @param {*} params
 * @returns
 */
export function pageStatistics(tab, params) {
  return http.get(`/invoice/management/vat/statistics/${tab}`, params)
}

/**
 * 增值税发票
 * @param {*} params
 * @returns
 */
export function getVatPage(tab, params) {
  return http.get(`/invoice/management/vat/page/${tab}`, params)
}

/**
 * 增值税发票查看
 * @param {*} params
 * @returns
 */
export function getVatDetail(id) {
  return http.get(`/invoice/management/vat/${id}`)
}

/**
 * 增值税发票审核 -- 驳回
 * @param {*} params
 * @returns
 */
export function review(id, params) {
  return http.post(`/invoice/management/vat/review/${id}`, params)
}

/**
 * 增值税发票导出
 * @param {*} params
 * @returns
 */
export function vatExport(tab, params) {
  return http.downloadGet(`/invoice/management/vat/export/${tab}`, params)
}

/**
 *增值税发票附件查看
 * @param {*} params
 * @returns
 */
export function vatAnnexPreview(params) {
  return http.get('/invoice/management/vat/annex', params)
}

/**
 * 增值税发票批量发送短信
 * @param {*} params
 * @returns
 */
export function sendVatSms(tab, params) {
  return http.post(`invoice/management/vat/sendVatSms/${tab}`, params)
}

/**
 * 增值税发票批量发送短信数量统计
 * @param {*} params
 * @returns
 */
export function sendVatSmsCount(tab, params) {
  return http.post(`/invoice/management/vat/sendVatSmsCount/${tab}`, params)
}
