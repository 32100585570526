import { operationOptions, cmsOptions, operationType, channelTypeOptions, trueOrFalseOptions, sharePatternOptions, subsetTemplate, adjustmentTemplate } from '@/@core/utils/options'
import { subsetSplitEstimate, subsetSplitReversal, reversalImport, reversalExportFila, batchSubsetImport, importFailExport } from './service'

const mapEnum = {
  operationOptions,
  operationType,
  cmsOptions,
  channelTypeOptions,
  statusOptions: [
    { label: '全部', value: '' },
    { label: '未生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '无需生成', value: 2 },
  ],
  trueOrFalseOptions,
  sharePatternOptions,
  batchImportMap: [
    { label: '导入后，对应频道数据将被拆分，若子集已有拆分数据将被覆盖，是否继续？', value: '批量拆分子集', api: batchSubsetImport, exportApi: importFailExport, link: subsetTemplate },
    { label: '导入后，对应频道的调差数据将会覆盖，是否继续？', value: '批量调差', api: reversalImport, exportApi: reversalExportFila, link: adjustmentTemplate },
  ],
}

export default mapEnum

export const channelTypeMap = {
  singleOpen: 0, // 单开
  subset: 1, // 子集
  compilation: 2, // 合集
}

export const subsetApiMap = {
  estimate: subsetSplitEstimate,
  reversal: subsetSplitReversal,
}
