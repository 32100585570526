import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"app-link"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"app-link",attrs:{"color":!_vm.outlined ? 'primary' : '',"outlined":_vm.outlined}},'v-btn',attrs,false),on),[_vm._v(" 批量重新生成 "),_c('i',{staticClass:"iconfont icon-a-xialakuang2x fs12 ml5 pt2"})])]}}])},[_c(VList,[_c(VListItem,{staticClass:"fs14",on:{"click":function($event){return _vm.$emit('batchRegenerate')}}},[_vm._v(" 按逻辑生成 ")]),_c(VListItem,{staticClass:"fs14",on:{"click":_vm.regenerateByContractNo}},[_vm._v(" 按合约编号生成 ")])],1)],1),(_vm.visibleContractNo)?_c('ContractNumDialog',{attrs:{"title":"批量重新生成"},on:{"close":function($event){_vm.visibleContractNo = false},"submit":_vm.submit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }