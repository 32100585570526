<script>
import { asynchronousReturn } from '@/@core/utils'
import { numberValidator, required } from '@/@core/utils/validation'
import { ref, reactive } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { adjustDifferenceFB } from '../service'

export default {
  name: 'RealAdjustmentDialog',
  components: { Dialog },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()

    const formData = reactive({
      adjustActualIncomeRmb: undefined,
    })
    const { message } = useMessage()

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        adjustDifferenceFB(props.form.id, formData).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          emit('refresh')
          close()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    return {
      show,
      close,
      formRef,
      formData,

      submit,
      numberValidator,
      required,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="实发(¥)调差"
    width="400px"
    show-footer
    @cancel="close"
    @confirm="submit"
  >
    <v-form ref="formRef">
      <v-row class="firstRow">
        <v-col cols="4" align-self="end">
          实发(¥)：
        </v-col>
        <v-col>
          {{ form.incomeRmb }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label firstRow">
          调差(¥)：
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.adjustActualIncomeRmb"
            placeholder="请输入"
            outlined
            dense
            suffix="元"
            :rules="[required,numberValidator]"
            maxlength="20"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
        </v-col>
        <v-col class="remark">
          注：调增请输入正数，调减请输入负数
        </v-col>
      </v-row>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>

.col-4{
  text-align: right;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom: -18px;
}
.row + .row{
  margin-top: 0px;
}

.firstRow{
  font-size: 16px;
  color: rgba(0,0,0,0.85);
}

.label{
  padding-top: 20px;
  position: relative;
  &::before{
    content: '*';
    color: #f5222d;
    position: absolute;
    left: 40px;
  }
}
</style>
