import http from '@http'
import axios from '@axios'
import config from '../../../../config'

const prefix = config.serviceUrl
/**
 * 各tab总数统计
 * @param {*} params
 * @returns
 */
export function tabStatistics(params) {
  return http.get('/invoice/management/proforma/tab/statistics', params)
}

/**
 * 形式发票金额统计
 * @param {*} params
 * @returns
 */
export function pageStatistics(tab, params) {
  return http.get(`/invoice/management/proforma/statistics/${tab}`, params)
}

/**
 * 形式发票
 * @param {*} params
 * @returns
 */
export function getProformaPage(tab, params) {
  return http.get(`/invoice/management/proforma/${tab}`, params)
}

/**
 * 形式发票导出
 * @param {*} params
 * @returns
 */
export function proformaExport(tab, params) {
  return http.downloadGet(`/invoice/management/proforma/export/${tab}`, params)
}

/**
 * 形式发票作废
 * @id {*} id
 * @returns
 */
export function proformaCancel(id) {
  return http.get(`/invoice/management/cancel/${id}`)
}

/**
 * 形式发票-重新发起
 * @id {*} id
 * @returns
 */
export function proformaRegenerate(id) {
  return http.post(`/invoice/management/regenerate/${id}`)
}

/**
 * 形式发票-批量发送短信
 * @returns
 */
export function sendSms(tab, params) {
  return http.post(`/invoice/management/sendSms/${tab}`, params)
}

/**
 * 形式发票-批量发送短信统计
 * @returns
 */
export function sendSmsCount(tab, params) {
  return http.post(`/invoice/management/sendSmsCount/${tab}`, params)
}

/**
 * 形式发票-excel批量变更汇款状态
 * @returns
 */
export function proformaByImport(key, onUploadProgress) {
  return axios({
    url: `${prefix}/invoice/management/proforma/proformaUpdatePaymentStatusByImport?key=${key}`,
    method: 'post',
    onUploadProgress,
  })
}

/**
 * 形式发票-excel批量变更结果导出
 * @returns
 */
export function importFailExport(key) {
  return http.downloadGet(`/invoice/management/proforma/importFailExport?key=${key}`)
}
