<script>
import { required } from '@/@core/utils/validation'
import { asynchronousReturn } from '@/@core/utils'
import FormInput from '@/components/FormInput/index.vue'
import { onMounted, ref, computed, reactive, watch } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { unreceivedRestoreReceipted } from '../service'

export default {
  name: 'RestoreDialog',
  components: { FormInput },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const show = ref(true)
    const formRef = ref()

    watch(show, val => {
      if (!val) {
        emit('close')
      }
    })

    const formData = reactive({
      channelName: undefined,
      channelId: undefined,
      newReceiptedAt: undefined,
    })

    Object.assign(formData, props.row)

    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'channelName', label: '频道名称：', placeholder: '暂无', disabled: true, cols: 4,
      },
      {
        el: 'VTextField', props: 'channelId', label: '频道ID：', placeholder: '暂无', disabled: true, cols: 4,
      },
      {
        el: 'DatePickers', props: 'newReceiptedAt', label: '还原到账日期：', type: 'date', clearable: false, required: true, cols: 4, rules: [required],
      },
    ])

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        unreceivedRestoreReceipted(props.row.id, formData.newReceiptedAt).then(({ data }) => {
          if (data.status === 200) {
            message.success('还原成功')
            emit('refresh')
            show.value = false
          }
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    onMounted(() => {

    })

    return {
      formRef,
      show,
      submit,
      searchConfig,
      formData,
    }
  },
}
</script>
<template>
  <vl-dialog
    title="还原"
    :visible.sync="show"
    width="600px"
    top="0"
  >
    <v-form ref="formRef">
      <FormInput :columns="searchConfig" :value="formData" />
    </v-form>
    <div class="remark mt20">
      注：数据将还原到所选择的到账日期所在月份中，请根据实际填写
    </div>
    <template #footer>
      <div class="flex-right gap8">
        <v-btn
          outlined
          @click="show = false"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
        >
          保存
        </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>
<style scoped lang='scss'>
.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}
</style>
