<script>
import { formatNumber, addSpace } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { ref, reactive, onMounted, nextTick } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import AddOrEdit from './components/AddOrEdit.vue'
import { tableColumn } from './tabColumn'
import { getList } from './service'

export default {
  name: 'RateManagement',
  components: { AppView, SuperTable, Pagination, AddOrEdit },
  props: {},
  setup() {
    const loading = ref(false)
    const { message } = useMessage()
    const formRef = ref()
    const visibleAddOrEdit = ref(false)

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const tableData = ref([])
    const init = () => {
      loading.value = true
      getList({ ...page, total: undefined }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, tableColumn)
        page.total = data.total
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        init()
      })
    }

    const editForm = ref({})
    const addOrEdit = row => {
      editForm.value = row
      nextTick(() => {
        visibleAddOrEdit.value = true
      })
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const search = () => {
      page.page = 1
      init()
    }

    onMounted(() => {
      init()
    })

    return {
      loading,
      formRef,
      tableColumn,
      page,
      tableData,
      reset,
      search,
      init,
      handleCurrentChange,
      handleSizeChange,
      addOrEdit,
      addSpace,
      visibleAddOrEdit,
      editForm,
    }
  },
}
</script>
<template>
  <AppView class="px-5 pt-5">
    <template #main-header>
      <v-btn v-auth="['rate-management-add']" color="primary" @click="addOrEdit({id:undefined})">
        {{ addSpace('新增汇率') }}
      </v-btn>
    </template>
    <SuperTable
      :columns="tableColumn"
      :data="tableData"
      :loading="loading"
    >
      <template #objectId="{row}">
        <div>{{ row.objectName }}</div>
        <div>{{ row.objectId }}</div>
      </template>
      <template #actions="{row}">
        <span v-auth="['rate-management-edit']" class="app-link" @click="addOrEdit(row)">编辑</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <AddOrEdit v-if="visibleAddOrEdit" :row="editForm" @close="visibleAddOrEdit = false" @refresh="init" />
  </AppView>
</template>
<style scoped lang='scss'>
</style>
