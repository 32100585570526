<script>
import Tooltip from '@/components/tooltip/index.vue'
import { MessageBox as MessageBoxElement } from 'element-ui'
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import XwPopover from '@/components/XwPopover.vue'
import PreviewImg from '@/components/PreviewImg/index.vue'
import { isImgFormat } from '@/@core/utils/is'
import { formatNumber, processNumbers, addSpace } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { checkStatusColorMap, paymentStatusColorMap, remittanceStatusColorMap } from '@/@core/utils/colorMap'
import Details from '@/views/statementManagement/components/StatementDetail/index.vue'
import { downloadFile } from '@/@core/utils/form'
import NullifyDialog from '../../components/nullify/index.vue'
import ViewAndReviewDialog from './viewAndReviewDialog.vue'
import mapEnum, { filterMap } from '../mapEnum'
import { getVatPage, pageStatistics, vatExport, vatAnnexPreview, sendVatSms, sendVatSmsCount } from '../service'
import BatchChangeRemitDialog from '../../components/batch-change-remit-status/index.vue'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm, ViewAndReviewDialog, Details, PreviewImg, BatchChangeRemitDialog, XwPopover, XwStatusIcon, NullifyDialog, Tooltip },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '全部',
    },
    activeName: {
      type: String,
      default: '全部',
    },
  },
  setup(props, { emit }) {
    const table = ref()
    const selected = ref([])
    const formRef = ref()
    const { message } = useMessage()
    const searchForm = computed(() => props.formData).value
    const loading = ref(false)
    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称',
      },
      {
        el: 'DateMonthRange', props: 'payoutPeriod', label: 'Payout Period', type: 'month',
      },
      {
        el: 'VSelect', props: 'type', label: '发票类型', items: mapEnum.typeOptions, itemText: 'label', itemValue: 'value', auth: props.label === '待上传',
      },
      {
        el: 'DatePickers', props: 'time', label: '上传日期', clearable: true, range: true, auth: props.label === '待上传',
      },
      {
        el: 'VSelect', props: 'paymentStatus', label: '汇款状态', items: mapEnum.paymentStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '审核通过',
      },
      {
        el: 'VSelect', props: 'sendSmsStatus', label: '短信状态', items: mapEnum.sendSmsStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '待上传',
      },
    ])

    const newSearchConfig = computed(() => searchConfig.value.filter(item => !item.auth))

    const searchMap = {
      time: {
        uploadStartAt: searchForm.time[0],
        uploadEndAt: searchForm.time[1],
      },
      payoutPeriod: {
        payoutPeriodStart: searchForm.payoutPeriod[0],
        payoutPeriodEnd: searchForm.payoutPeriod[1],
      },
    }

    function getSearchFormFields() {
      const arr = newSearchConfig.value.map(item => item.props)

      return arr.reduce((result, key) => {
        if (key in searchForm) {
          result[key] = searchForm[key]
        }

        // if (Array.isArray(searchForm[key])) result[key] = searchMap[key]

        return result
      }, {})
    }

    // console.log(getSearchFormFields())
    const newSearchForm = computed(() => ({
      ...getSearchFormFields(),
      uploadStartAt: props.label === '待上传' ? undefined : searchForm.time[0],
      uploadEndAt: props.label === '待上传' ? undefined : searchForm.time[1],
      payoutPeriodStart: searchForm.payoutPeriod[0],
      payoutPeriodEnd: searchForm.payoutPeriod[1],
      time: undefined,
      payoutPeriod: undefined,
    }))

    // console.log(newSearchForm.value)
    const statisticsForm = ref({})
    const viewAndReviewDialogVisible = ref(false)
    const visibleDetails = ref(false)
    const previewVisible = ref(false)
    const visibleBatchChangeRemit = ref(false)
    const visibleNullify = ref(false)
    const tableData = ref([])
    const activeRow = ref({})
    const init = () => {
      loading.value = true
      getVatPage(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        emit('updateTotal', newSearchForm.value)
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      pageStatistics(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    // 导出
    const exportExcel = () => {
      message.info('导出中...请稍等')
      vatExport(props.tab, { ...newSearchForm.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的增值税发票${props.label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    const created = () => {
      init()
      if (props.label !== '已驳回') {
        getStatisticsForm()
      }
    }

    const refresh = () => {
      table.value.clear()
      created()
    }

    // 打开详情弹窗
    const detailForm = ref({})
    const openDetail = (settlementNo, platform) => {
      visibleDetails.value = true
      detailForm.value.settlementNo = settlementNo
      detailForm.value.platform = platform
    }

    // 查看并审核
    const reviewId = ref(0)
    const review = id => {
      viewAndReviewDialogVisible.value = true
      reviewId.value = id
    }

    // 查看文件
    const filePath = ref('')
    const previewFile = async item => {
      try {
        const { data } = await vatAnnexPreview({ filePath: item.filePath })
        if (!isImgFormat(item.fileName)) {
          window.open(data.data)
        } else {
          previewVisible.value = true
          filePath.value = data.data
        }
      } catch (error) {
        message.error(error.response.data.message)
      }
    }

    // 批量变更汇款状态
    const batchRemitForm = ref({})

    const batchChangeStatus = () => {
      batchRemitForm.value = {
        ids: selected.value.map(item => item.id),
        ...newSearchForm.value,
      }
      visibleBatchChangeRemit.value = true
    }

    // 发送短信
    const sendInfo = (total, not, has) => {
      MessageBoxElement.alert(`
      <p>已选择<span  style="color:#9155FD"> ${total || 0} </span> 条，其中<span style="color:#9155FD"> ${not || 0} </span>条未发送，<span  style="color:#9155FD"> ${has || 0} </span>条已发送或已发送未回执，是否确认发送短信通知？</p>`, '批量发送短信', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: addSpace('取消'),
        confirmButtonText: addSpace('确定'),
      }).then(() => {
        sendVatSms(props.tab, { ...newSearchForm.value, ids: selected.value.map(item => item.id) }).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          refresh()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }
    // 批量发送短信
    const batchSendSms = () => {
      if (!selected.value.length) {
        sendVatSmsCount(props.tab, newSearchForm.value).then(({ data }) => {
          sendInfo(data.data.totalSmsCount, data.data.failedSmsCount, data.data.successSmsCount)
        }).catch(error => message.error(error.response.data.message))
      } else {
        sendInfo(selected.value.length, selected.value.filter(item => [0, 3].includes(item.sendSmsStatus)).length, selected.value.filter(item => [1, 2].includes(item.sendSmsStatus)).length)
      }
    }

    // 作废
    const nullify = row => {
      activeRow.value = row
      visibleNullify.value = true
    }

    const getSettlementNoList = arr => arr.flatMap(({ platform, settlementNoList }) => settlementNoList.map(settlementNo => ({ platform, settlementNo })))
    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.time = []
        searchForm.payoutPeriod = []
        searchForm.type = ''
        created()
      }).catch(error => error)
    }

    const search = () => {
      page.page = 1
      created()
    }

    onMounted(() => {
      if (props.activeName === props.label) {
        created()
      }
    })

    return {
      newSearchConfig,
      formRef,
      viewAndReviewDialogVisible,
      page,
      tableData,
      reset,
      search,
      init,
      created,
      refresh,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      searchForm,
      selectionChange,
      table,

      review,
      reviewId,
      selected,
      checkStatusColorMap,
      paymentStatusColorMap,
      openDetail,
      visibleDetails,
      detailForm,
      getSettlementNoList,
      loading,
      processNumbers,
      addSpace,
      exportExcel,
      previewVisible,
      previewFile,
      filePath,
      visibleBatchChangeRemit,
      batchRemitForm,
      batchChangeStatus,
      nullify,
      visibleNullify,
      activeRow,
      batchSendSms,
      remittanceStatusColorMap,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="newSearchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center">
          <v-btn v-if="label === '审核通过'" v-auth="['VATinvoice-changestatus']" class="app-link" color="primary" @click="batchChangeStatus">
            批量变更汇款状态
          </v-btn>
          <v-btn v-if="label === '待上传'" v-auth="['VATinvoice-sendSms']" class="app-link" color="primary" @click="batchSendSms">
            批量发送短信
          </v-btn>
          <v-btn v-auth="['VATinvoice-export']" class="app-link" :color="['审核通过','待上传'].includes(label) ? '' : 'primary'" :outlined="['审核通过','待上传'].includes(label) ? true : false" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
          <div v-if="tab === 1 && selected.length" class="ml12 fs14">
            已选择 <span class="app-link">{{ selected.length }}</span> 条
          </div>
        </div>
        <div v-if="label !== '已驳回'" class="num">
          <span> 关联结算单金额: <span class="amount"> {{ processNumbers(statisticsForm.revenue, '￥' ) }} </span></span>
        </div>
      </div>
    </template>

    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      :checkbox-config="{reserve:true}"
      :row-config="{keyField:'id'}"
      @selection-change="selectionChange"
    >
      <template #settlementList="{row}">
        <xw-popover :list="row.settlementList" @openDetail="openDetail"></xw-popover>
      </template>

      <template #reviewAnnex="{row}">
        <div v-if="(row.reviewAnnex || []).length">
          <div v-for="item,index in row.reviewAnnex" :key="index" class="download-link" @click="previewFile(item)">
            {{ item.fileName }}
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #checkStatus="{row}">
        <xw-status-icon :color="checkStatusColorMap[row.checkStatus]" :text="filterMap('checkStatusOptions',row.checkStatus)" />
      </template>

      <template #paymentStatus="{row}">
        <xw-status-icon :color="paymentStatusColorMap[row.paymentStatus]" :text=" row.paymentStatus" />
      </template>

      <template #updatedAt="{row}">
        <div v-if="label === '审核通过'">
          {{ row.paymentStatus === '已汇款' ? row.updatedAt : '-' }}
        </div>
        <div v-else>
          {{ row.updatedAt || '-' }}
        </div>
      </template>

      <template #expressNum="{row}">
        <div v-if="!row.express && !row.expressNum">
          -
        </div>
        <div v-else>
          <div>{{ row.express || '-' }}</div>
          <div>{{ row.expressNum || '-' }}</div>
        </div>
      </template>

      <template #sendSmsStatus="{row}">
        <div class="flex-left gap5">
          <xw-status-icon :color="remittanceStatusColorMap[row.sendSmsStatus]" :text="filterMap('sendSmsStatusOptions',row.sendSmsStatus)" />
          <Tooltip v-if="row.sendSmsError" bottom>
            <div>{{ row.sendSmsError }}</div>
          </Tooltip>
        </div>
        <div>
          {{ row.sendSmsTime }}
        </div>
      </template>

      <template #actions="{row}">
        <span v-if="label !== '待上传'" v-auth="['VATinvoice-view-audit']" class="app-link" @click="review(row.id)">{{ label === '待审核' ? '查看并审核' : '查看' }}</span>
        <span v-if="label === '待上传'" v-auth="['VATinvoice-cancel']" class="app-link" @click="nullify(row)">回退</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <ViewAndReviewDialog v-if="viewAndReviewDialogVisible" :id="reviewId" :tab="tab" @close="viewAndReviewDialogVisible = false" @refresh="refresh" />

    <Details v-if="visibleDetails" :form="detailForm" @close="visibleDetails = false" />

    <PreviewImg v-if="previewVisible" :list="[filePath]" @close="previewVisible = false" />

    <BatchChangeRemitDialog v-if="visibleBatchChangeRemit" :form="batchRemitForm" platform="vat" :tab="tab" @close="visibleBatchChangeRemit = false" @refresh="refresh" />

    <NullifyDialog v-if="visibleNullify" :active-row="activeRow" invoice-type="vat" @close="visibleNullify = false" @refresh="refresh" />
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  .amount{
      color: #FFA80F;
      padding: 0 10px;
  }
}
</style>
