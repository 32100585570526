const list = [
  { text: '编号', value: 'number', minWidth: 200, showOverflow: false },
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '结算金额 (￥)', value: 'revenue', minWidth: 150, showOverflow: false, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '签字状态', value: 'signStatus', minWidth: 120, showOverflow: false },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
]

const list1 = [
  { text: '编号', value: 'number', minWidth: 200, showOverflow: false },
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '结算金额 (￥)', value: 'revenue', minWidth: 150, showOverflow: false, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '签字状态', value: 'signStatus', minWidth: 120, showOverflow: false },
  { text: '失败原因', value: 'failReason', minWidth: 200, showOverflow: false },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
]

const list2 = [
  { text: '编号', value: 'number', minWidth: 200, showOverflow: false },
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '结算金额 (￥)', value: 'revenue', minWidth: 150, showOverflow: false, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '签字状态', value: 'signStatus', minWidth: 120, showOverflow: false },
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
]

const list3 = [
  { text: '编号', value: 'number', minWidth: 200, showOverflow: false },
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '结算金额 (￥)', value: 'revenue', minWidth: 150, showOverflow: false, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '签字状态', value: 'signStatus', minWidth: 120, showOverflow: false },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '短信状态/最近发送时间', value: 'sendSmsStatus', minWidth: 200, showOverflow: false },
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
]

export const tableColumns1 = [
  ...list,
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 160, fixed: 'right' },
]

export const tableColumns2 = [
  ...list,
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const tableColumns3 = [
  { text: '', value: 'checkbox', type: 'checkbox', width: 50 },
  ...list2,
  { text: '签字时间', value: 'signAt', minWidth: 200 },
  { text: '最后修改人', value: 'updatedUserName', minWidth: 120 },
  { text: '最后修改时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const tableColumns4 = [
  ...list1,
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 160, fixed: 'right' },
]

export const tableColumns5 = [
  { text: '', value: 'checkbox', type: 'checkbox', width: 50 },
  ...list3,
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 160, fixed: 'right' },
]
