<script>
import moment from 'moment'
import { ref, reactive } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import { tableColumn1, tableColumn2, tableColumn4, tableColumn5 } from './tabCols'
import commonTable from './components/commonTable.vue'

export default {
  name: 'FBAccountFormList',
  components: { AppView, TabPanePro, TabsPro, commonTable },
  props: {},
  setup() {
    const activeName = ref('zg')
    const componentRef = ref()
    const tabList = [
      { label: '暂估报表', value: 'zg', element: 'commonTable', mapColumn: tableColumn1, authExport: ['fbAccountForm-estimate-export'], authRebuild: ['fbAccountForm-estimate-batch-rebuild'] },
      { label: 'INVOICE版', value: 'total', element: 'commonTable', mapColumn: tableColumn2, authExport: ['fbAccountForm-invoice-export'], authRebuild: ['fbAccountForm-invoice-batch-rebuild'] },
      { label: '达美金调整', value: 'hundred', element: 'commonTable', mapColumn: tableColumn2, authExport: ['fbAccountForm-hundred-export'], authRebuild: ['fbAccountForm-hundred-batch-rebuild'] },
      { label: 'REMITTANCE版', value: 'remittance', element: 'commonTable', mapColumn: tableColumn4, authRebuild: ['fbAccountForm-remittance-batch-rebuild'] },
      { label: '未达百美金余额', value: 'ltHundred', element: 'commonTable', mapColumn: tableColumn5, authExport: ['fbAccountForm-ltHundred-export'], authRebuild: ['fbAccountForm-ltHundred-batch-rebuild'] },
    ]

    const searchForm = reactive({
      month: [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')],
      channelType: undefined,
      objectId: undefined,
      objectName: undefined,
      hasContract: undefined,
      subsetName: undefined,
      settlementCreatedStatus: '',
    })

    const tabClick = val => {
      activeName.value = val.name

      const index = tabList.findIndex(item => item.value === val.name)
      if (val.name !== 'remittance') {
        componentRef.value[index].isShow = true
      } else {
        componentRef.value[index].isShow = false
      }
      componentRef.value[index].search()
    }

    return {
      activeName,
      componentRef,
      tabList,
      tabClick,
      searchForm,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro
      v-model="activeName"
      style="height: 100%;"
      @tabClick="tabClick"
    >
      <tab-pane-pro
        v-for="item in tabList"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
        <component
          :is="item.element"
          ref="componentRef"
          :key="item.value"
          :form-data="searchForm"
          :header="item.mapColumn"
          :tab="item.value"
          :label="item.label"
          :active-name="activeName"
          :tab-list="tabList"
        >
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  height: calc( 100% - 50px);
}
</style>
