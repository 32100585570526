<script>
import { onMounted, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { generateYt, generateFb } from '../service'

export default {
  name: 'CreateFinalDialog',
  components: { Dialog },
  props: {
    text: {
      type: String,
      default: '123',
    },
    stringList: {
      type: Array,
      default: () => [
        '1、确保当前月份频道数量和整体收益正确，调差和子集拆分完成',
        '2、频道类型=合集（已拆）、单开（已拆），不生成结算单',
        '3、未到账列表、未找到合约和已生成结算单，不生成结算单',
      ],
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { message, loading } = useMessage()
    const show = ref(true)

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const ids = ref([])
    const submit = () => {
      const loadingRef = loading()
      if (props.form.platform === 'YT') {
        generateYt({ ids: ids.value, month: props.form.month[0], count: props.form.data.length }).then(({ data }) => {
          if (data.status === 200) {
            message.success('生成结算单成功')
            emit('refresh')
            close()
          }
        }).catch(error => {
          message.error(error.response?.data.message || error)
        }).finally(() => {
          loadingRef.close()
        })
      } else {
        generateFb({ ids: ids.value, month: props.form.month[0], count: props.form.data.length }).then(({ data }) => {
          if (data.status === 200) {
            message.success('生成结算单成功')
            emit('refresh')
            close()
          }
        }).catch(error => {
          message.error(error.response?.data.message || error)
        }).finally(() => {
          loadingRef.close()
        })
      }
    }

    onMounted(() => {
      if (props.form.platform === 'YT') {
        ids.value = props.form.data.filter(item => item.settlementCreatedStatus === 0).map(ele => ele.id)
      } else {
        ids.value = props.form.data.filter(item => item.settlementCreatedStatus === 0 && item.hasContract).map(ele => ele.id)
      }
    })

    return {
      show,
      close,
      submit,
      ids,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    title="生成结算单"
    :subtitle-text="`(${form.month.join('-')})`"
    width="550px"
    @confirm="submit"
    @cancel="close"
  >
    <div class="px-5">
      <div v-if="!form.data.length" class="text">
        当前未勾选任何数据，为【全部生成结算单】，是否确认生成？
      </div>
      <div v-else class="text">
        已选择 <span>{{ form.data.length }}</span> 条数据，<span>{{ ids.length }}</span> 条可生成结算单，是否确认生成？
      </div>
      <div v-if="!form.data.length" class="mt-8">
        <div class="tips">
          {{ stringList[0] || '' }}
        </div>
        <div class="tips">
          {{ stringList[1] || '' }}
        </div>
        <div class="tips">
          {{ stringList[2] || '' }}
        </div>
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.text{
  color: rgba($color: #000000, $alpha: 0.85);
  span{
    color: #9155FD;
  }
}
.tips{
  color: #d81a30;
  font-size: 14px;
}
</style>
