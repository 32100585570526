<script>
import { ref } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import ContractNumDialog from './ContractNumDialog.vue'

export default {
  name: 'BatchRegenerateBtn',
  components: { ContractNumDialog },
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const visibleContractNo = ref(false)
    const { message } = useMessage()
    const regenerateByContractNo = () => {
      if (!props.selected.length) return message.warning('请至少选择一条数据')
      visibleContractNo.value = true
    }

    const submit = formData => {
      emit('regenerateByContractNum', formData)
    }

    return {
      regenerateByContractNo, visibleContractNo, submit,
    }
  },
}
</script>
<template>
  <span class="app-link">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          :color="!outlined ? 'primary' : ''"
          :outlined="outlined"
          v-bind="attrs"
          class="app-link"
          v-on="on"
        >
          批量重新生成 <i class="iconfont icon-a-xialakuang2x fs12 ml5 pt2"></i>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="fs14" @click="$emit('batchRegenerate')">
          按逻辑生成
        </v-list-item>
        <v-list-item class="fs14" @click="regenerateByContractNo">
          按合约编号生成
        </v-list-item>
      </v-list>
    </v-menu>
    <ContractNumDialog v-if="visibleContractNo" title="批量重新生成" @close="visibleContractNo = false" @submit="submit" />
  </span>
</template>
<style scoped lang='scss'>
</style>
