import mapEnum from './mapEnum'

export const tableColumn = [
  { text: '名称', value: 'name', minWidth: 180, enum: [...mapEnum.report.FB, ...mapEnum.report.YT] },
  { text: '月份', value: 'month', minWidth: 180 },
  { text: '状态', value: 'status', minWidth: 120 },
  { text: '失败原因', value: 'reason', minWidth: 200, showOverflow: false },
  { text: '发起人', value: 'createdUser', minWidth: 120 },
  { text: '发起时间', value: 'createdAt', minWidth: 180 },
]

export const noSettlement = [
  { text: '平台', value: 'platform', minWidth: 80 },
  { text: '频道/主页名称', value: 'channelName', minWidth: 200, showOverflow: false },
  { text: '频道/主页ID', value: 'channelId', minWidth: 200, showOverflow: false },
  { text: '频道类型', value: 'channelType', minWidth: 80 },
  { text: '子集名称', value: 'subsetName', minWidth: 200, showOverflow: false },
  { text: '原因', value: 'reason', minWidth: 200, showOverflow: false },
  { text: '添加人', value: 'createdUserName', minWidth: 100 },
  { text: '添加时间', value: 'createdAt', minWidth: 180 },
  { text: '操作', value: 'actions', fixed: 'right', minWidth: 80 },
]
