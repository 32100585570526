import mapEnum from './mapEnum'

const list = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtFb' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, apiText: 'Payout Period' },
  { text: '主页名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '主页名称,主页ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '发票状态', value: 'invoiceStatus', minWidth: 120 },
]

const list1 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtFb' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, apiText: 'Payout Period' },
  { text: '主页名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '主页名称,主页ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '主页收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
]

const list2 = [
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
]

const list3 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtFb' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, apiText: 'Payout Period' },
  { text: '主页名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '主页名称,主页ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '主页收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
]

const list4 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  // { text: '月份', value: 'month', minWidth: 100 },
  { text: '所属月份', value: 'month', minWidth: 120 },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, apiText: 'Payout Period' },
  { text: '主页名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '主页名称,主页ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '原因', value: 'remark', minWidth: 180, showOverflow: false },
  { text: '主页收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
]

const list5 = [
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtFb' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, apiText: 'Payout Period' },
  { text: '主页名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '主页名称,主页ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '发票状态', value: 'invoiceStatus', minWidth: 120 },
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '主页收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
]

export const tableColumns1 = [
  ...list1,
  { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100 },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', minWidth: 200, fixed: 'right' },
]

export const tableColumns2 = [
  ...list1,
  // { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', minWidth: 160, fixed: 'right' },
]

export const tableColumns3 = [
  ...list,
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '主页收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
]

export const tableColumns4 = [
  ...list4,
  { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', minWidth: 160, fixed: 'right' },
]

export const tableColumns5 = [
  ...list3,
  { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', minWidth: 180, fixed: 'right' },
]

export const tableColumns6 = [
  ...list5,
  { text: 'FB平台预提税 ($)', value: 'withholdingTax', minWidth: 150, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
]
