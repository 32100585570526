<script>
import { numberValidator, required, requiredNonSpace } from '@/@core/utils/validation'
import FormInput from '@/components/FormInput/index.vue'
import { asynchronousReturn } from '@/@core/utils'
import { ref, reactive, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { save, domesticChannels } from '../service'

export default {
  name: 'AddChannel',
  components: { Dialog, FormInput },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // 返回数据map、单开：1、子集：0，合集：0 ， if ( type === 0) 是合集还是子集得看有没有选择子集名称
    // if (type === 1) 隐藏 子集名称列表
    // 正确map、 单开：0、子集：1、合集：2

    const { message } = useMessage()
    const show = ref(true)
    const formRef = ref()
    const formData = reactive({
      platform: 'YT',
      channelId: undefined,
      channelName: undefined,
      subsetName: undefined,
      reason: undefined,
      collectionTypeValue: undefined,
    })

    const isShow = ref(false)
    const subsetNameList = ref([])
    const signChannelId = computed(() => {
      if (formData.collectionTypeValue === 1) {
        return (subsetNameList.value.length && Number(subsetNameList.value[0].channelId)) || undefined
      }
      if (!formData.subsetName) {
        return undefined
      }

      return Number(subsetNameList.value.find(item => item.title === formData.subsetName).channelId) || undefined
    })

    const channelType = computed(() => {
      if (formData.collectionTypeValue === undefined) {
        return undefined
      }

      if (formData.collectionTypeValue === 1) {
        return 0
      }

      if (formData.subsetName) {
        return 1
      }

      return 2
    })

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const searchConfig = computed(() => [
      {
        el: 'RadioGroup', props: 'platform', label: '平台', radioList: [{ label: 'YT', value: 'YT' }, { label: 'FB', value: 'FB' }], row: true,
      },
      { el: 'VTextField', props: 'channelId', label: '频道ID', required: true, rules: [required], maxlength: 50 },
      { el: 'VTextField', props: 'channelName', label: '频道名称', maxlength: 100 },
      { el: 'VAutocomplete', props: 'subsetName', label: '子集名称', items: subsetNameList.value, itemText: 'title', itemValue: 'title', hidden: isShow.value },
      { el: 'VTextarea', props: 'reason', label: '原因', required: true, maxlength: 200, rules: [requiredNonSpace] },
    ])

    const change = ({ key, val }) => {
      if (key === 'channelId') {
        formData.channelId = val.replace(/\s+/g, '')
        domesticChannels({ channelId: formData.channelId }).then(({ data }) => {
          subsetNameList.value = data.data.channels
          formData.channelName = data.data.channelName
          formData.collectionTypeValue = data.data.collectionTypeValue
          if (data.data.collectionTypeValue === 1) isShow.value = true
        }).catch(error => message.error(error.response.data.message))
      }
    }
    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        save({ ...formData, signChannelId: signChannelId.value, channelType: channelType.value }).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          emit('refresh')
          close()
        }).catch(error => {
          message.error(error?.response?.data?.message || error)
        })
      }).catch(error => error)
    }

    return {
      show,
      close,
      formRef,
      formData,
      submit,
      numberValidator,
      required,
      searchConfig,
      change,
      signChannelId,
      subsetNameList,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="添加频道/主页"
    width="550px"
    show-footer
    @cancel="close"
    @confirm="submit"
  >
    <v-form ref="formRef">
      <FormInput :columns="searchConfig" :value="formData" @change="change"></FormInput>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>

</style>
