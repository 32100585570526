<script>
import batchRegenerateBtn from '@/views/components/batchRegenerateBtn/index.vue'
import { subsetTemplate } from '@/@core/utils/options'

import { formatNumber, addSpace, addPercent } from '@/@core/utils'
import { downloadFile } from '@/@core/utils/form'
import Pagination from '@/components/Pagination.vue'
import { useMessage } from '@/hooks/useMessage'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment'
import { ref, reactive, onMounted, nextTick, watch, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import ProgressLoading from '@/components/ProgressLoading/index.vue'
import { MessageBox as MessageBoxElement } from 'element-ui'
import mapEnum, { channelTypeMap } from '../mapEnum'
import { estimateList, estimateDelete, estimateExport, estimateRegenerateAuto, batchSubsetImport, importFailExport, estimateByContractNum } from '../service'
import { tableColumn1 } from '../tabCols'
import Subset from './subset.vue'
import BatchImportDialog from './batchImportDialog.vue'
import ImportFailDialog from './importFailDialog.vue'

export default {
  name: 'Provisional',
  components: { AppView, SearchForm, SuperTable, Subset, Pagination, ProgressLoading, BatchImportDialog, ImportFailDialog, batchRegenerateBtn },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '',
    },
    activeName: {
      type: String,
      default: 'zg',
    },
  },
  setup(props) {
    const form = ref()
    const batchRegenerateBtnRef = ref()
    const table = ref()
    const loading = ref(false)
    const { MessageBox, message } = useMessage()

    const searchForm = computed(() => props.formData).value

    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const page = reactive({
      total: 0,
      page: 1,
      pageSize: 20,
    })

    const revenueForm = reactive({
      type: 'estimate',
      id: undefined,
      channelId: undefined,
      title: undefined,
    })

    const searchConfig = ref([
      {
        el: 'DateMonthRange',
        props: 'month',
        label: '月份',
        type: 'month',
        clearable: false,
      },
      {
        el: 'VTextField',
        props: 'title',
        label: '频道名称',
      },
      {
        el: 'VTextField',
        props: 'channelId',
        label: '频道ID',
      },
      {
        el: 'VSelect',
        props: 'cms',
        label: '收款系统',
        items: mapEnum.cmsOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'channelType',
        label: '频道类型',
        items: mapEnum.channelTypeOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VTextField',
        props: 'subsetName',
        label: '子集名称',
      },
      {
        el: 'VSelect',
        props: 'sharePattern',
        label: '分成模式',
        items: mapEnum.sharePatternOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'hasContract',
        label: '是否找到合约',
        items: mapEnum.trueOrFalseOptions,
        itemText: 'label',
        itemValue: 'value',
      },
    ])
    const tableData = ref([])
    const selected = ref([])

    const visibleSubset = ref(false)
    const visibleLoading = ref(false)
    const visibleBatchSplit = ref(false)
    const visibleImportFail = ref(false)
    const upPercentage = ref(0)

    const init = () => {
      loading.value = true
      estimateList({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined }).then(({ data }) => {
        tableData.value = formatNumber(data.data, tableColumn1)
        page.total = data.total
        selected.value = []
        table.value.clear()
      }).catch(error => {
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      }).finally(() => {
        loading.value = false
      })
    }
    const del = id => {
      MessageBox({
        message: '确认删除？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      })
        .then(() => {
          estimateDelete(id)
            .then(({ data }) => {
              if (data.status === 200) message.success('删除成功')
              init()
            })
            .catch(error => message.error(error.response.data.message))
        })
        .catch(error => error)
    }

    const split = item => {
      visibleSubset.value = true
      revenueForm.id = item.id
      revenueForm.channelId = item.channelId
      revenueForm.title = item.channelName
    }

    const search = () => {
      page.page = 1
      init()
    }

    const reset = async () => {
      await form.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        init()
      })
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      estimateExport({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined })
        .then(data => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的暂估报表`
          downloadFile(data.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }

    // 批量重新生成
    const batchRegenerate = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      const newArr = selected.value
        .filter(item => item.channelType === 1 || !item.channelSplitStatus)
        .map(item => item.id)

      MessageBoxElement.alert(`
      <p style="font-size:16px;margin-top:10px;">已选择 <span style="color:#9155FD;">${selected.value.length}</span> 条，<span style="color:#9155FD">${newArr.length}</span> 条可重新生成，是否确认重新生成？</p>
      <p style="color:#F24545; margin-top:20px;font-size:14px;">注：单开（已拆）和合集（已拆）类型的频道，无法重新生成，需要先将已经拆分的子集删除</p>`, '重新生成', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: addSpace('取消'),
        confirmButtonText: addSpace('确定'),
      }).then(() => {
        if (!newArr.length) return message.warning('没有可重新生成的数据')
        visibleLoading.value = true
        estimateRegenerateAuto({ ids: newArr }, progressEvent => {
          upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        })
          .then(({ data }) => {
            if (data.status === 200) message.success('重新生成成功')
            init()
          })
          .catch(error => {
            MessageBox({
              title: '重新生成',
              message: error.response.data.message,
              type: 'error',
              showCancelButton: false,
              confirmBtnText: addSpace('我知道了'),
            }).then(() => {
              // 暂不做操作
            })
          })
          .finally(() => {
            visibleLoading.value = false
          })
      }).catch(error => error)
    }
    // 合约编号生成
    const regenerateByContractNum = formData => {
      console.log(formData)
      const ids = selected.value.map(item => item.id)
      estimateByContractNum({ ids, ...formData }).then(() => {
        message.success('操作成功')
        batchRegenerateBtnRef.value.visibleContractNo = false
        search()
      }).catch(error => message.error(error.response.data.message))
    }

    // click批量拆分子集
    const batchSplit = () => {
      if (times.value.monthStart !== times.value.monthEnd) return message.warning('请选择同一月份')
      visibleBatchSplit.value = true
    }
    const failList = ref([])
    const fileKey = ref([])
    // 提交批量拆分子集
    const batchSubmit = key => {
      fileKey.value = key
      MessageBox({
        message: '导入后，对应频道数据将被拆分，若子集已有拆分数据将被覆盖，是否继续？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        visibleLoading.value = true
        batchSubsetImport({ key: encodeURIComponent(key), dateTime: times.value.monthStart, type: props.tab }, progressEvent => {
          upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        }).then(() => {
          message.success('导入成功')
          visibleBatchSplit.value = false
          init()
        }).catch(error => {
          const { data } = error.response
          if (data.data.length) {
            failList.value = data.data
            visibleImportFail.value = true
          }
          message.error(error.response.data.message)
        })
          .finally(() => {
            visibleLoading.value = false
          })
      }).catch(error => error)
    }

    // 导出错误文件
    const downLoadErrorFile = () => {
      importFailExport({ key: encodeURIComponent(fileKey.value) }).then(res => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的失败原因文件`
        downloadFile(res.data, fileNameDownload)
      }).catch(error => message.error(error.response.data.message))
    }

    // 关闭失败原因弹窗
    const closeBatch = () => {
      visibleBatchSplit.value = false
      visibleImportFail.value = false
      init()
    }

    // 下载模板
    const clickDownTemplate = () => {
      window.open(subsetTemplate)
      message.success('下载成功')
    }

    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(async () => {
      if (props.activeName === props.tab) {
        init()
      }
    })

    watch(
      () => searchForm.month,
      newVal => {
        searchForm.month = newVal
      },
    )

    return {
      searchConfig,
      searchForm,
      table,
      tableData,
      tableColumn1,
      reset,
      search,
      init,
      visibleSubset,
      exportExcel,
      del,
      split,
      page,
      revenueForm,
      handleCurrentChange,
      handleSizeChange,
      form,
      filterMap,
      channelTypeMap,
      selectionChange,

      loading,
      addSpace,
      batchRegenerate,
      upPercentage,
      visibleLoading,
      visibleBatchSplit,
      batchSubmit,
      clickDownTemplate,
      batchSplit,
      failList,
      visibleImportFail,
      downLoadErrorFile,
      closeBatch,
      addPercent,
      selected,
      regenerateByContractNum,
      batchRegenerateBtnRef,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="form" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>
    <template #main-header>
      <batchRegenerateBtn ref="batchRegenerateBtnRef" v-auth="['ytAccountForm-batch-rebuild']" :selected="selected" @batchRegenerate="batchRegenerate" @regenerateByContractNum="regenerateByContractNum" />
      <v-btn v-auth="['ytAccountForm-batch-split-subset']" class="app-link" outlined @click="batchSplit">
        {{ addSpace('批量拆分子集') }}
      </v-btn>
      <v-btn v-auth="['ytAccountForm-estimate-export']" class="app-link" outlined @click="exportExcel">
        {{ addSpace('导出') }}
      </v-btn>
    </template>
    <SuperTable ref="table" :columns="tableColumn1" :data="tableData" :loading="loading" @selection-change="selectionChange">
      <template #channelName="{ row }">
        <div>{{ row.channelName }}</div>
        <div>{{ row.channelId }}</div>
      </template>
      <template #cms="{ row }">
        {{ filterMap('cmsOptions', row.cms) }}
      </template>
      <template #channelType="{ row }">
        <span v-if="!row.channelSplitStatus">{{ filterMap('channelTypeOptions', row.channelType) }}</span>
        <span v-else> {{ filterMap('channelTypeOptions', row.channelType) }}(已拆) </span>
      </template>
      <template #federalTax="{ row }">
        {{ addPercent(row.federalTax) }}
      </template>
      <template #singaporeTax="{ row }">
        {{ addPercent(row.singaporeTax) }}
      </template>
      <template #proportion="{ row }">
        {{ addPercent(row.proportion) }}
      </template>
      <template #serviceCharge="{ row }">
        {{ addPercent(row.serviceCharge) }}
      </template>
      <template #actions="{ row }">
        <span v-if="row.channelType === channelTypeMap.subset" v-auth="['ytAccountForm-del-subset']" class="app-link" @click="del(row.id)">删除</span>
        <span
          v-if="[channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType)"
          v-auth="['ytAccountForm-subset']"
          class="app-link"
          @click="split(row)"
        >拆分子集</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Subset v-if="visibleSubset" :form="revenueForm" @close="visibleSubset = false" @refresh="init" />

    <ProgressLoading v-if="visibleLoading" :progress="upPercentage" @close="visibleLoading = false" />

    <BatchImportDialog v-if="visibleBatchSplit" title="批量拆分子集" @close="visibleBatchSplit = false" @submit="batchSubmit">
      <div class="template">
        <div>
          1、下载 <span class="download-link" @click="clickDownTemplate">&lt;批量拆分子集模板&gt;</span>；
        </div>
        <div>2、按规则填写后，上传文件。</div>
      </div>
    </BatchImportDialog>

    <ImportFailDialog v-if="visibleImportFail" :data="failList" @close="visibleImportFail = false" @closeBatch="closeBatch" @downLoadErrorFile="downLoadErrorFile" />
  </AppView>
</template>
<style scoped lang="scss">
span {
  cursor: pointer;
}

::v-deep .app-view__main {
  overflow: hidden;
}

.template{
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  div{
    line-height: 30px;
  }
}

</style>
