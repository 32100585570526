import { isEmpty } from './index'

export const required = value => {
  if (value === null || value === undefined || value === '') {
    return '这是必填项'
  }

  if (Array.isArray(value) && value.length === 0) {
    return '这是必填项'
  }

  return true
}

export const requiredNonSpace = value => {
  if (value) {
    if (value.trim() === '') {
      return '这是必填项' // 输入值为空或只包含空格，校验失败
    }

    return true // 输入值有效，校验通过
  }

  return '这是必填项'
}

export const booleanValidator = value => (value === 0 || value === 1 ? true : '这是必选项')

export const isNotNullValidator = value => (value === '' || value === null ? '这是必选项' : true)

export const specialCharValidator = item => /[`[\]]/im.test(item)

export const emptyValidator = item => item === '' || typeof item === 'undefined' || item === null

// eslint-disable-next-line consistent-return
export const timeValidator = time => {
  if (time !== null) return time.split(':')[0] >= new Date().getHours() && time.split(':')[1] >= new Date().getMinutes()

  return false
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  // const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  const regExp = /(?=.*\d)(?=.*[a-zA-Z])([!@#$%&*()]?).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || '密码最少要包含1个数字，1个大写字母或小写字母，最少8位'

  // 'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars'
  )
}

export const codeValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return /^-?[0-9]+$/.test(String(value)) || '请输入正确验证码'
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const positiveValidator = value => value > 0 || '必填，且必须大于0'

export const positiveIntegerValidator = value => (value?.toString()?.includes('.') ? '必填，且必须为大于0的整数' : value > 0 || '必填，且必须为大于0的整数')

// 限制输入数字、小数点和负号，长度20，小数点后2位
export const numberValidator = inputValue => {
  // 如果输入为空，则直接通过验证
  if (!inputValue) {
    return ''
  }

  // 正则表达式：匹配数字、小数点和负号，长度最多20，小数点后最多2位
  const regex = /^-?\d{0,20}(?:\.\d{0,2})?$/

  if (!regex.test(inputValue)) {
    return '只能输入数字、小数点和负号，长度最多20，小数点后最多2位'
  }

  return true
}
// 限制输入数字、小数点和负号，长度10，小数点后2位
export const numberValidator10 = inputValue => {
  // 如果输入为空，则直接通过验证
  if (!inputValue) {
    return ''
  }

  // 正则表达式：匹配数字、小数点和负号，长度最多10，小数点后最多2位
  const regex = /^-?\d{0,10}(?:\.\d{0,2})?$/

  if (!regex.test(inputValue)) {
    return '只能输入数字、小数点和负号，长度最多10，小数点后最多2位'
  }

  return true
}

/**
 * 只能输入8 位数字
 * @param {Number} input
 * @returns Boolean
 */
export function validateNumberInput8(input) {
  // 去除输入中的空格
  const sanitizedInput = input?.replace(/\s/g, '')

  // 使用正则表达式检查去除空格后的输入是否为8位数字
  const isValid = /^\d{8}$/.test(sanitizedInput)

  // 返回验证结果
  return isValid
}

export function validateNumberInput12(input) {
  // 去除输入中的空格
  const sanitizedInput = input?.replace(/\s/g, '')

  // 使用正则表达式检查去除空格后的输入是否为8位数字
  const isValid = /^\d{12}$/.test(sanitizedInput)
  // 返回验证结果

  return isValid
}

export function validateNumberInput20(input) {
  // 去除输入中的空格
  const sanitizedInput = input?.replace(/\s/g, '')

  // 使用正则表达式检查去除空格后的输入是否为8位数字
  const isValid = /^\d{20}$/.test(sanitizedInput)

  // 返回验证结果
  return isValid
}

export function validateDateRange(startDate, endDate, num = 6) {
  // 将选择的日期转换为 Date 对象
  const start = new Date(startDate)
  const end = new Date(endDate)

  // 获取三个月后的日期
  const threeMonthsLater = new Date(start)
  threeMonthsLater.setMonth(start.getMonth() + num)

  // 如果结束日期在三个月后的日期之前，并且开始日期在当前日期之前，则返回 true，否则返回 false
  return end <= threeMonthsLater && start <= end
}

// 限制输入数字 + 小数点，小数点后4位
export const numberValidator4 = inputValue => {
  // 如果输入为空，则直接通过验证
  if (!inputValue) {
    return ''
  }

  // 正则表达式：匹配数字、小数点和负号，长度最多10，小数点后最多2位
  const regex = /^-?\d*(\.\d{0,4})?$/

  if (!regex.test(inputValue)) {
    return '只能输入数字、小数点，长度最多8，小数点后最多4位'
  }

  return true
}

// 禁止前后有空格
export const noSpaceValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  return (!value.startsWith(' ') && !value.endsWith(' ')) || '前后禁止有空格'
}
