<script>
import { requiredNonSpace, noSpaceValidator } from '@/@core/utils/validation'
import { reactive, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { asynchronousReturn } from '@/@core/utils'

export default {
  name: 'ContractNumDialog',
  components: { Dialog },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()
    const formData = reactive({
      contractNum: undefined,
    })

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        emit('submit', formData)
      }).catch(error => error)
    }

    return { show, close, submit, formRef, formData, requiredNonSpace, noSpaceValidator }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="title"
    show-footer
    width="400px"
    @cancel="close"
    @confirm="submit"
  >
    <vl-form ref="formRef">
      <vl-form-item required label="合约编号：">
        <v-text-field v-model="formData.contractNum" outlined dense :rules="[requiredNonSpace, noSpaceValidator]" maxlength="100"></v-text-field>
      </vl-form-item>
    </vl-form>
  </Dialog>
</template>
<style scoped lang='scss'>

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom: -10px !important;
  padding-top: 6px;
}

::v-deep .vl-form-item{
  gap: 12px;
}
</style>
