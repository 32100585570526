import http from '@http'

/**
 * 汇率管理
 * @param {*} params
 * @returns
 */
export function getList(params) {
  return http.get('/rate/page', params)
}

/**
 * 新增-编辑汇率
 * @param {*} params
 * @returns
 */
export function rateEdit(params) {
  return http.post('/rate/edit', params)
}
