import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-view"},[_vm._l((_vm.separatedArray()),function(items,index){return [_c('span',_vm._g({directives:[{name:"auth",rawName:"v-auth",value:(items.vAuth),expression:"items.vAuth"}],key:index,staticClass:"app-link"},_vm.removeEvent(items)),[_vm._v(" "+_vm._s(items.label)+" ")])]}),(_vm.remainArray().length)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"app-link menuButton"},on),[_vm._v(" "+_vm._s(_vm.dropdownLabel)+" "),_c(VIcon,{attrs:{"color":"#9155fd","size":"17"}},[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)]}}],null,false,4123104297)},[_c(VList,_vm._l((_vm.remainArray()),function(items,index){return _c(VListItem,{directives:[{name:"auth",rawName:"v-auth",value:(items.vAuth),expression:"items.vAuth"}],key:index},[_c(VListItemTitle,_vm._g({staticStyle:{"cursor":"pointer"}},_vm.removeEvent(items)),[_c('div',{staticClass:"app-link",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(items.label)+" ")])])],1)}),1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }