<script>
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import { statusPointColorMap } from '@/@core/utils/colorMap'
import { formatNumber } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, watch, nextTick } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import { getRecords } from './service'
import mapEnum from './mapEnum'

export default {
  name: 'YTVerifyList',
  components: { AppView, SearchForm, SuperTable, Pagination, XwStatusIcon },
  props: {},
  setup() {
    const { message } = useMessage()
    const formRef = ref()
    const activeName = ref('video')
    const searchForm = reactive({
      month: undefined,
      platform: undefined,
    })

    const loading = ref(false)
    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const searchConfig = computed(() => [
      {
        el: 'DatePickers', props: 'month', label: '月份', type: 'month', clearable: true,
      },
      {
        el: 'VSelect', props: 'platform', label: '平台', items: mapEnum.platformType, itemText: 'label', itemValue: 'value', clearable: true,
      },
    ])

    const tableColumn = computed(() => [
      { text: '平台', value: 'platform', enum: mapEnum.platformType },
      { text: '所属月份', value: 'month' },
      { text: '生成类型', value: 'type', enum: mapEnum.typeOptions },
      { text: '数量', value: 'quantity', align: 'right' },
      { text: '状态', value: 'status', enum: mapEnum.statusOptions },
      { text: '发起人', value: 'createdUser' },
      { text: '发起时间', value: 'createdAt', width: 200 },
    ])

    const tableData = ref([])
    const init = () => {
      loading.value = true
      getRecords({ ...page, total: undefined, ...searchForm }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, tableColumn.value)
        page.total = data.total
      }).catch(error => {
        message.error(error.response.data.message)
        tableData.value = []
        page.total = 0
        loading.value = false
      })
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        init()
      })
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const search = () => {
      page.page = 1
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      init()
    })

    watch(() => searchForm.month, newVal => {
      searchForm.month = newVal
    })

    return {
      formRef,
      tableColumn,
      activeName,
      page,
      searchForm,
      searchConfig,
      tableData,
      reset,
      search,
      handleCurrentChange,
      handleSizeChange,
      filterMap,
      exportExcel,
      statusPointColorMap,
      loading,
    }
  },
}
</script>
<template>
  <AppView class="px-5 pt-5">
    <template #header>
      <div style="width: 100%">
        <SearchForm
          ref="formRef"
          class="pa-0 pb-5"
          :columns="searchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <SuperTable
      :columns="tableColumn"
      :data="tableData"
      :loading="loading"
    >
      <template #status="{ row }">
        <xw-status-icon :color="statusPointColorMap[row.status]" :text="filterMap('statusOptions',row.status)" />
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </AppView>
</template>
<style scoped lang='scss'>
.num{
color: rgba($color: #000000, $alpha:0.8);
padding: 0 10px;
span{
    color: #FFA80F;
    padding: 0 10px;
}
}
</style>
