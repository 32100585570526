<script>
import moment from 'moment'
import { numberValidator, required } from '@/@core/utils/validation'
import FormInput from '@/components/FormInput/index.vue'
import { asynchronousReturn, addSpace } from '@/@core/utils'
import { ref, reactive, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { debounce } from '@/@core/utils/form'
import { isExist, createReport } from '../service'
import mapEnum from '../mapEnum'

export default {
  name: 'AddGenerateReport',
  components: { Dialog, FormInput },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()

    const formSearch = reactive({
      platform: 'YT',
    })

    const formData = ref({
      name: mapEnum.report[formSearch.platform][0].value,
      month: moment().subtract(1, 'month').format('yyyy-MM'),
    })

    const { message, MessageBox, loading } = useMessage()

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const searchForm = ref([
      {
        el: 'RadioGroup', props: 'platform', label: '平台：', radioList: mapEnum.platformType, row: true,
      },
    ])

    const searchConfig = computed(() => [
      {
        el: 'RadioGroup', props: 'name', label: '报表：', radioList: mapEnum.report[formSearch.platform], row: true,
      },
      {
        el: 'DatePickers', props: 'month', label: '月份：', type: 'month', min: moment().subtract(1, 'month').format('yyyy-MM'), max: '2099-12', clearable: false, hidden: formData.value.name === 'FB-verification',
      },
    ])

    const change = ({ val }) => {
      formData.value.name = mapEnum.report[val][0].value
    }

    const create = () => {
      const loadingRef = loading()
      createReport(formData.value).then(({ data }) => {
        if (data.status === 200) {
          message.success('操作成功')
          emit('refresh')
          close()
        }
      }).catch(error => message.error(error.response.data.message))
        .finally(() => loadingRef.close())
    }

    const createDebounceFn = debounce(create, 1000)
    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        isExist(formData.value).then(({ data }) => {
          if (data.data) {
            MessageBox({
              message: '所选月份报表数据已存在，重新生成将覆盖原报表数据，是否确认重新生成？',
              type: 'warning',
              showCancelButton: true,
              cancelBtnText: addSpace('取消'),
              confirmBtnText: addSpace('生成'),
            }).then(() => {
              createDebounceFn()
            })
          } else {
            createDebounceFn()
          }
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    return {
      show,
      close,
      formRef,
      formData,
      searchForm,
      formSearch,

      submit,
      numberValidator,
      required,
      searchConfig,
      change,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="生成报表"
    width="600px"
    show-footer
    confirm-btn-text="生成"
    @cancel="close"
    @confirm="submit"
  >
    <v-form ref="formRef">
      <FormInput :columns="searchForm" :value="formSearch" @change="change"></FormInput>
      <FormInput :columns="searchConfig" :value="formData"></FormInput>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>
::v-deep .v-input--radio-group__input{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
</style>
