import http from '@http'
import axios from '@axios'
import config from '../../../../config'

const prefix = config.serviceUrl
/**
 * 暂估报表FB
 * @param {*} params
 * @returns
 */
export function estimateFB(params) {
  return http.get('/fbFinance/provisionalEstimate/page', params)
}

/**
 * 暂估报表导出
 * @param {*} data
 * @returns
 */
export function estimateExportFB(params) {
  return http.downloadGet('/fbFinance/provisionalEstimate/export', params)
}

/**
 * invoice| 达美金调整 -FB
 * @param {*} params
 * @returns
 */
export function invoiceFB(params, type) {
  return http.get(`/fbFinance/invoice/${type}/page`, params)
}

/**
 * invoice | 达美金调整 -导出--FB
 * @param {*} params
 * @returns
 */
export function invoiceExportFB(params, type) {
  return http.downloadGet(`/fbFinance/invoice/${type}/export`, params)
}

/**
 * remittance -FB
 * @param {*} params
 * @returns
 */
export function remittancePageFb(params) {
  return http.get('/fbFinance/remittance/page', params)
}

/**
 * remittance -导出 --FB
 * @param {*} params
 * @returns
 */
export function remittanceExportFB(params) {
  return http.downloadGet('/fbFinance/remittance/export', params)
}

/**
 * 导出实发差值明细 -导出 --FB
 * @param {*} params
 * @returns
 */
export function actualIncomeRmbDiffExportExportFB(params) {
  return http.downloadGet('/fbFinance/actualIncomeRmbDiffExport/export', params)
}

/**
 * remittance页列表上方统计 -导出 --FB
 * @param {*} params
 * @returns
 */
export function remittanceStatisticsFB(params) {
  return http.get('/fbFinance/remittance/statistics', params)
}

/**
 * remittance实发调差 --FB
 * @param {*} params
 * @returns
 */
export function adjustDifferenceFB(id, params) {
  return http.post(`/fbFinance/adjustDifference/${id}`, params)
}

/**
 * 暂估报表重新生成 对接CRM、AMS
 * @param {*} params
 * @returns
 */
export function estimateRegenerateBatchAuto(data, onUploadProgress) {
  return axios({
    url: `${prefix}/fbFinance/estimate/regenerateBatchAuto`,
    method: 'post',
    onUploadProgress,
    data,
  })
}

/**
 * Invoice报表重新生成 对接CRM、AMS
 * @param {*} params
 * @returns
 */
export function invoiceRegenerateBatchAuto(data, onUploadProgress) {
  return axios({
    url: `${prefix}/fbFinance/invoice/regenerateBatchAuto`,
    method: 'post',
    onUploadProgress,
    data,
  })
}

/**
 * Remittance报表重新生成 对接CRM、AMS
 * @param {*} params
 * @returns
 */
export function remittanceRegenerateBatchAuto(data, onUploadProgress) {
  return axios({
    url: `${prefix}/fbFinance/remittance/regenerateBatchAuto`,
    method: 'post',
    onUploadProgress,
    data,
  })
}

/**
 * 暂估报表按合约编号重新生成
 * @param {*} params
 * @returns
 */
export function estimateRegenerateByContractNum(params) {
  return http.post('/fbFinance/estimate/regenerateBatchByContractNumAuto', params)
}

/**
 * Invoice报表按合约编号重新生成
 * @param {*} params
 * @returns
 */
export function invoiceRegenerateByContractNum(params) {
  return http.post('/fbFinance/invoice/regenerateBatchByContractNumAuto', params)
}

/**
 * Remittance报表按合约编号重新生成
 * @param {*} params
 * @returns
 */
export function remittanceRegenerateByContractNum(params) {
  return http.post('/fbFinance/remittance/regenerateBatchByContractNumAuto', params)
}
