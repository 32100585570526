<script>
import { required } from '@/@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import AppTooltip from '@/components/AppTooltip.vue'

export default {
  name: 'AutocompleteRemote',
  components: { AppTooltip },
  props: {
    defaultOptions: {
      type: Object,
      default: () => ({
        text: 'signChannelName',
        value: 'signChannelId',
      }),
    },
    searchKey: {
      type: String,
      default: 'channelName',
    },
    selectList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const search = ref('')
    const list = ref([])
    const newValue = ref(null)

    function getSignChannelList(newVal) {
      list.value = newVal.map(item => ({
        ...item,
        text: item[props.defaultOptions.text],
        value: item[props.defaultOptions.value],
        copyrightHolder: item.cpName,
      }))
    }

    watch(() => props.selectList, newVal => {
      getSignChannelList(newVal)
    })

    function valueChange(e) {
      console.log(e)

      const data = list.value.find(item => item.value === e)
      newValue.value = e
      if (data) {
        emit('input', e)
        emit('change', e, data)
      }
    }

    return {
      search,
      required,
      list,
      newValue,
      valueChange,
    }
  },
}
</script>

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="newValue"
    :items="list"
    placeholder="请输入"
    dense
    hide-details
    outlined
    clearable
    @change="valueChange"
  >
    <template #prepend-item>
      <div style="position: sticky; top: 0; background-color: #ffffff; z-index: 99999">
        <div class="flex-left px15 py12" style="width: 390px; background-color: #ffffff;">
          <div style="width: 190px; font-weight: bold" class="fs14">
            频道名称
          </div>
          <div style="width: 170px; font-weight: bold" class="fs14">
            CP名称
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </template>
    <template #item="{item}">
      <template v-if="typeof item !== 'object'">
        <v-list-item-content v-text="item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content style="line-height: 20px">
          <div class="flex-left" style="width: 360px">
            <div style="width: 190px;" class="fs14">
              <AppTooltip :label="item.text || '-'" height="28" width="190" />
            </div>
            <div style="width: 170px; height: 28px" class="fs14">
              <AppTooltip :label="item.copyrightHolder || '-'" height="28" width="170" />
            </div>
          </div>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<style scoped lang="scss">
::v-deep .v-list-item {
  height: 38px !important;
}
</style>
