import mapEnum from './mapEnum'

const list = [
  { text: 'Object Name/ID', value: 'objectName', width: 200 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '二级部门', value: 'second', width: 120 },
  { text: '三级部门', value: 'third', width: 120 },
  { text: '四级部门', value: 'fourth', width: 120 },
  { text: '主页属性', value: 'operationType', width: 120, enum: mapEnum.operationType },
  { text: '频道类型', value: 'channelType', width: 120, enum: mapEnum.channelTypeOptions },
  { text: '子集名称', value: 'subsetName', width: 130, showOverflow: false },
  { text: '分成模式', value: 'sharePattern', width: 120, enum: mapEnum.sharePatternOptions },
  { text: '是否找到合约', value: 'hasContract', width: 120, enum: mapEnum.hasContractOptions },
  { text: '无合约原因', value: 'noContractReason', width: 150, showOverflow: false },
]

const list1 = [
  { text: '可分配收益', value: 'distributableIncome', width: 120, align: 'right' },
  { text: '客户分成比例', value: 'proportion', width: 120, align: 'right' },
  { text: '客户应发收益 ($)', value: 'partnerRevenueDollar', width: 150, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '付款手续费 ($)', value: 'taxDollar', width: 150, align: 'right' },
  { text: '客户实发收益 ($)', value: 'incomeTotal', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 80, align: 'right' },
  { text: '客户实发收益 (￥)', value: 'incomeRmb', width: 150, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '收款人', value: 'bankAccountName', width: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 200 },
  { text: '收款账号', value: 'bankAccount', width: 220 },
]

const list2 = [
  { text: 'Object Name/ID', value: 'objectName', width: 200 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '二级部门', value: 'second', width: 120 },
  { text: '三级部门', value: 'third', width: 120 },
  { text: '四级部门', value: 'fourth', width: 120 },
  { text: '主页属性', value: 'operationType', width: 120, enum: mapEnum.operationType },
  { text: '频道类型', value: 'channelType', width: 120, enum: mapEnum.channelTypeOptions },
  { text: '子集名称', value: 'subsetName', width: 130, showOverflow: false },
  { text: '分成模式', value: 'sharePattern', width: 120, enum: mapEnum.sharePatternOptions },
  { text: '是否找到合约', value: 'hasContract', width: 120, enum: mapEnum.hasContractOptions },
  { text: '无合约原因', value: 'noContractReason', width: 150, showOverflow: false },
]

const list3 = [
  { text: '可分配收益', value: 'distributableIncome', width: 120, align: 'right' },
  { text: '客户分成比例', value: 'proportion', width: 120, align: 'right' },
  { text: '客户应发收益 ($)', value: 'partnerRevenueDollar', width: 150, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '付款手续费 ($)', value: 'taxDollar', width: 150, align: 'right' },
  { text: '客户实发收益 ($)', value: 'incomeTotal', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 80, align: 'right' },
  { text: '客户实发收益 (￥)', value: 'incomeRmb', width: 150, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '实发调差 (￥)', value: 'adjustActualIncomeRmb', width: 150, align: 'right' },
  { text: '收款人', value: 'bankAccountName', width: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 200 },
  { text: '收款账号', value: 'bankAccount', width: 220 },
]
export const tableColumn1 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  ...list2,
  { text: 'earning (6号)', value: 'earnings', width: 120, align: 'right' },
  ...list1,
  { text: '无需生成原因', value: 'settlementNoCreatedReason', width: 200 },
]

export const tableColumn2 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: 'Payout Reference', value: 'payoutReference', width: 180 },
  { text: 'Payout ID', value: 'payoutId', width: 180 },
  { text: 'Product', value: 'product', width: 200, align: 'left' },
  { text: 'Payout Period', value: 'payoutPeriod', width: 150 },
  ...list,
  { text: 'Amount', value: 'amount', width: 120, align: 'right' },
  ...list1,
  { text: '无需生成原因', value: 'settlementNoCreatedReason', width: 200 },

]

export const tableColumn4 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  // { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Reference', value: 'payoutReference', width: 180 },
  { text: 'Product', value: 'product', width: 200 },
  { text: '到账日期', value: 'receiptedAt', width: 100 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  ...list,
  { text: 'earning (25号)', value: 'earnings', width: 150, align: 'right' },
  { text: 'Amount', value: 'amount', width: 120, align: 'right' },
  { text: 'Payout', value: 'payout', width: 120, align: 'right' },
  { text: 'Withholding Tax', value: 'withholdingTax', width: 150, align: 'right' },
  { text: 'Remittance', value: 'remittance', width: 120, align: 'right' },
  ...list3,
  { text: '结算单生成状态', value: 'settlementCreatedStatus', width: 160, enum: mapEnum.statusOptions },
  { text: '无需生成原因', value: 'settlementNoCreatedReason', width: 200 },
  { text: '操作', value: 'actions', fixed: 'right', width: 120 },
]

export const tableColumn5 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: 'Payout Reference', value: 'payoutReference', width: 180 },
  { text: 'Payout ID', value: 'payoutId', width: 180 },
  { text: 'Product', value: 'product', width: 200, align: 'left' },
  { text: 'Payout Period', value: 'payoutPeriod', width: 150 },
  ...list,
  { text: 'Amount', value: 'amount', width: 120, align: 'right' },
  ...list1,
]
