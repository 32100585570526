<script>
import mainHeaderButtonList from '@/components/ButtonList/mainHeaderButtonList.vue'
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import { MessageBox as MessageBoxElement } from 'element-ui'
import { processNumbers, formatNumber, addSpace, addPercent } from '@/@core/utils'
import ProgressLoading from '@/components/ProgressLoading/index.vue'
import { statusPointColorMap } from '@/@core/utils/colorMap'
import Pagination from '@/components/Pagination.vue'
import { useMessage } from '@/hooks/useMessage'
import { downloadFile } from '@/@core/utils/form'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment'
import { ref, reactive, onMounted, nextTick, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import TooltipBase from '@/components/common/tooltip-base/TooltipBase.vue'
import batchRegenerateBtn from '@/views/components/batchRegenerateBtn/index.vue'
import ImportFailDialog from './importFailDialog.vue'
import BatchImportDialog from './batchImportDialog.vue'
import mapEnum, { channelTypeMap } from '../mapEnum'
import { reportCid, reversalList, reversalExport, reversalDelete, reversalRegenerate, joinUnreceived, actualRmbDiffExport, reversalByContractNum } from '../service'
import { tableColumn2 } from '../tabCols'
import Subset from './subset.vue'
import Adjust from './adjust.vue'
import NotListDialog from './notListDialog.vue'
import createFinalDialog from './createFinalDialog.vue'

export default {
  name: 'RunCell',
  components: {
    AppView,
    SearchForm,
    SuperTable,
    Subset,
    Adjust,
    Pagination,
    createFinalDialog,
    NotListDialog,
    BatchImportDialog,
    ProgressLoading,
    ImportFailDialog,
    XwStatusIcon,
    TooltipBase,
    batchRegenerateBtn,
    mainHeaderButtonList,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '',
    },
    activeName: {
      type: String,
      default: 'estimate',
    },
  },
  setup(props) {
    const form = ref()
    const batchRegenerateBtnRef = ref()
    const table = ref()
    const loading = ref(false)
    const { message, MessageBox } = useMessage()

    const searchForm = computed(() => props.formData).value

    const type = ref('批量调差')
    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const revenueForm = reactive({
      info: {
        type: 'reversal',
        id: undefined,
        title: undefined,
        channelId: undefined,
      },
      list: {
        cmsRevenueAdjust: undefined,
        cmsRevenueAdjustRemark: undefined,
        cmsRevenueSgAdjust: undefined,
        cmsRevenueSgAdjustRemark: undefined,
        cmsRevenueUsAdjust: undefined,
        cmsRevenueUsAdjustRemark: undefined,
      },
    })
    const searchConfig = ref([
      {
        el: 'DateMonthRange',
        props: 'month',
        label: '到账月份',
        type: 'month',
        clearable: false,
      },
      {
        el: 'VTextField',
        props: 'title',
        label: '频道名称',
      },
      {
        el: 'VTextField',
        props: 'channelId',
        label: '频道ID',
      },
      {
        el: 'VSelect',
        props: 'cms',
        label: '收款系统',
        items: mapEnum.cmsOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'channelType',
        label: '频道类型',
        items: mapEnum.channelTypeOptions,
        multiple: true,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VTextField',
        props: 'subsetName',
        label: '子集名称',
      },
      {
        el: 'VSelect',
        props: 'sharePattern',
        label: '分成模式',
        items: mapEnum.sharePatternOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'hasContract',
        label: '是否找到合约',
        items: mapEnum.trueOrFalseOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'settlementCreatedStatus',
        label: '结算单生成状态',
        items: mapEnum.statusOptions,
        itemText: 'label',
        itemValue: 'value',
      },
    ])
    const visibleAdjust = ref(false)
    const visibleSubset = ref(false)
    const visibleCreateFinal = ref(false)
    const visibleNotList = ref(false)
    const visibleBatchAdjust = ref(false)
    const visibleLoading = ref(false)
    const visibleImportFail = ref(false)

    const cidForm = ref({

    })
    const tableData = ref([])
    const selected = ref([])
    const upPercentage = ref(0)
    const getCidList = () => {
      reportCid(times.value)
        .then(({ data }) => {
          cidForm.value = data.data || {}
        })
        .catch(error => message.error(error.response.data.message))
    }

    const init = () => {
      loading.value = true
      reversalList({
        ...page,
        total: undefined,
        ...searchForm,
        ...times.value,
        month: undefined,
        channelType: props.tab === 'reversal' ? searchForm.channelType.join(',') : searchForm.channelType,
      })
        .then(({ data }) => {
          selected.value = []
          table.value.clear()
          tableData.value = formatNumber(data.data, tableColumn2)
          page.total = data.total
        })
        .catch(error => {
          tableData.value = []
          page.total = 0
          message.error(error.response.data.message)
        })
        .finally(() => {
          loading.value = false
        })
    }

    const search = () => {
      page.page = 1
      init()
      getCidList()
    }

    const reset = async () => {
      await form.value.resetForm()
      nextTick(() => {
        searchForm.month = [
          moment().subtract(1, 'month').format('yyyy-MM'),
          moment().subtract(1, 'month').format('yyyy-MM'),
        ]
        init()
        getCidList()
      })
    }

    // 拆分
    const split = item => {
      visibleSubset.value = true
      revenueForm.info.id = item.id
      revenueForm.info.channelId = item.channelId
      revenueForm.info.title = item.channelName
    }

    // 调差
    const adjustDifference = item => {
      revenueForm.info.id = item.id
      revenueForm.info.title = item.channelName
      visibleAdjust.value = true
      const {
        cmsRevenueAdjust,
        cmsRevenueAdjustRemark,
        cmsRevenueSgAdjust,
        cmsRevenueSgAdjustRemark,
        cmsRevenueUsAdjust,
        cmsRevenueUsAdjustRemark,
      } = item
      const newList = {
        cmsRevenueAdjust,
        cmsRevenueAdjustRemark,
        cmsRevenueSgAdjust,
        cmsRevenueSgAdjustRemark,
        cmsRevenueUsAdjust,
        cmsRevenueUsAdjustRemark,
      }
      Object.assign(revenueForm.list, newList)
    }

    const findItemMap = () => mapEnum.batchImportMap.find(item => item.value === type.value)

    const del = id => {
      MessageBox({
        message: '确认删除？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      })
        .then(() => {
          reversalDelete(id)
            .then(() => {
              message.success('删除成功')
              init()
            })
            .catch(error => message.error(error.response.data.message))
        })
        .catch(error => error)
    }

    const clickDownTemplate = () => {
      window.open(findItemMap().link)
      message.success('下载成功')
    }

    // 表格背景色
    const conditions = [
      { indexRange: [0, 6], color: 'rgba(145,85,253,0.04)' },
      { indexRange: [6, 12], color: 'rgba(44,160,248,0.04)' },
      { indexRange: [12, 23], color: 'rgba(255,168,15,0.04)' },
      { indexRange: [23, 37], color: 'rgba(0,176,155,0.04)' },
    ]
    const headerCellStyle = ({ columnIndex }) => {
      const matchedCondition = conditions.find(({ indexRange }) => {
        const [startIndex, endIndex] = indexRange

        return columnIndex >= startIndex && columnIndex < endIndex
      })

      // 如果找到匹配的条件，则返回对应的样式，否则返回默认样式
      return matchedCondition ? { backgroundColor: matchedCondition.color } : {}
    }
    const cellStyle = ({ columnIndex }) => {
      const matchedCondition = conditions.find(({ indexRange }) => {
        const [startIndex, endIndex] = indexRange

        return columnIndex >= startIndex && columnIndex < endIndex
      })

      // 如果找到匹配的条件，则返回对应的样式，否则返回默认样式
      return matchedCondition ? { backgroundColor: matchedCondition.color } : {}
    }

    // 导出Excel
    const exportExcel = () => {
      message.info('导出中...请稍等')
      reversalExport({
        ...searchForm,
        ...times.value,
        month: undefined,
        channelType: searchForm.channelType.join(',') })
        .then(data => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的冲销报表`
          downloadFile(data.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }

    /**
     * 导出实发调差报表
     */
    const exportDifference = () => {
      message.info('导出中...请稍等')
      actualRmbDiffExport({ ...page,
        total: undefined,
        ...searchForm,
        ...times.value,
        month: undefined,
        channelType: searchForm.channelType.join(',') }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的实发差值明细报表`
        downloadFile(data.data, fileNameDownload)
      })
    }

    const checkBoxChange = ({ records }) => {
      selected.value = records
    }

    const selectAllEvent = ({ records }) => {
      selected.value = records
    }

    /**
     * 批量重新生成
     */
    const batchGenerate = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      const newArr = selected.value
        .filter(item => item.channelType === 1 || !item.channelSplitStatus)
        .map(item => item.id)

      MessageBoxElement.alert(
        `<p style="font-size:16px;margin-top:10px;">已选择 <span style="color:#9155FD;">${selected.value.length}</span> 条，<span style="color:#9155FD">${newArr.length}</span> 条可重新生成，是否确认重新生成？</p>
         <p style="color:#F24545; margin-top:20px;font-size:14px;">注：单开（已拆）和合集（已拆）类型的频道，无法重新生成，需要先将已经拆分的子集删除</p>`,
        '重新生成',
        {
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          cancelButtonText: addSpace('取消'),
          confirmButtonText: addSpace('确定'),
        },
      )
        .then(() => {
          if (!newArr.length) return message.warning('没有可重新生成的数据')
          visibleLoading.value = true
          reversalRegenerate({ ids: newArr }, progressEvent => {
            upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          })
            .then(({ data }) => {
              if (data.status === 200) message.success('重新生成成功')
              init()
            })
            .catch(error => {
              MessageBox({
                title: '重新生成',
                message: error.response.data.message,
                type: 'error',
                showCancelButton: false,
                confirmBtnText: addSpace('我知道了'),
              }).then(() => {
                // 暂不做操作
              })
            })
            .finally(() => {
              visibleLoading.value = false
            })
        })
        .catch(error => error)
    }

    // 合约编号生成
    const regenerateByContractNum = formData => {
      console.log(formData)
      const ids = selected.value.map(item => item.id)
      reversalByContractNum({ ids, ...formData }).then(() => {
        message.success('操作成功')
        batchRegenerateBtnRef.value.visibleContractNo = false
        search()
      }).catch(error => message.error(error.response.data.message))
    }

    /**
     * 生成结算单
     */
    const createFinal = () => {
      if (times.value.monthStart !== times.value.monthEnd) return message.warning('请选择同一月份')
      visibleCreateFinal.value = true
    }
    /**
     * 未到账列表
     */
    const notList = () => {
      visibleNotList.value = true
    }
    /**
     * 加入未到账
     */
    const joinNot = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      const flag = selected.value.some(item => [channelTypeMap.compilation, channelTypeMap.singleOpen].includes(item.channelType))
      if (!flag) return message.warning('请选择单开/合集数据')
      const ids = selected.value.map(item => item.id)
      joinUnreceived({ ids })
        .then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          init()
        })
        .catch(error => message.error(error.response.data.message))
    }

    /**
     * 批量调差
     */
    const batchAdjustDifference = () => {
      type.value = '批量调差'
      if (times.value.monthStart !== times.value.monthEnd) return message.warning('请选择同一月份')
      visibleBatchAdjust.value = true
    }

    const failList = ref([])
    const fileKey = ref('')
    /**
     * 批量调差 和 批量拆分子集 提交
     * @param {*} val
     */
    const batchSubmit = key => {
      fileKey.value = key
      MessageBox({
        message: findItemMap().label,
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        visibleLoading.value = true
        findItemMap()
          .api({ key: encodeURIComponent(key), dateTime: times.value.monthStart, type: props.tab }, progressEvent => {
            upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          })
          .then(() => {
            message.success('导入成功')
            visibleBatchAdjust.value = false
            init()
          })
          .catch(error => {
            const { data } = error.response
            if (data.data.length) {
              failList.value = data.data
              visibleImportFail.value = true
            }
            message.error(error.response.data.message)
          })
          .finally(() => {
            visibleLoading.value = false
          })
      })
    }

    // 下载批量调差导入错误文件
    const downLoadErrorFile = data => {
      findItemMap()
        .exportApi({ data, key: encodeURIComponent(fileKey.value) })
        .then(res => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的失败原因文件`
          downloadFile(res.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }
    // 批量拆分子集
    const batchSplit = () => {
      type.value = '批量拆分子集'

      if (times.value.monthStart !== times.value.monthEnd) return message.warning('请选择同一月份')
      visibleBatchAdjust.value = true
    }
    // 关闭失败原因弹窗
    const closeBatch = () => {
      visibleBatchAdjust.value = false
      visibleImportFail.value = false
      init()
      getCidList()
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      if (props.activeName === props.tab) {
        init()
        getCidList()
      }
    })

    const btnList = ref([
      {
        label: '批量调差',
        click: batchAdjustDifference,
        vAuth: ['ytAccountForm-adjust-difference'],
        outlined: true,
      },
      {
        label: '批量拆分子集',
        click: batchSplit,
        vAuth: ['ytAccountForm-batch-split-subset'],
        outlined: true,
      },
      {
        label: '加入未到账',
        click: joinNot,
        vAuth: ['ytAccountForm-join-reversal'],
        outlined: true,
      },
      {
        label: '未到账列表',
        click: notList,
        vAuth: ['ytAccountForm-reversal-list'],
        outlined: true,
      },
      {
        label: '导出报表',
        click: exportExcel,
        vAuth: ['ytAccountForm-reversal-export'],
        outlined: true,
      },
      {
        label: '导出实发差值明细',
        click: exportDifference,
        vAuth: ['ytAccountForm-reversal-difference-export'],
        outlined: true,
      },
    ])

    return {
      form,
      table,
      visibleAdjust,
      visibleSubset,
      visibleCreateFinal,
      visibleNotList,
      searchConfig,
      searchForm,
      tableColumn2,
      tableData,
      page,
      revenueForm,
      cidForm,
      reset,
      search,
      del,
      init,
      split,
      batchGenerate,
      exportExcel,
      cellStyle,
      headerCellStyle,
      adjustDifference,
      checkBoxChange,
      selectAllEvent,
      handleCurrentChange,
      handleSizeChange,
      filterMap,
      channelTypeMap,
      selected,
      createFinal,
      notList,
      joinNot,

      loading,
      statusPointColorMap,
      batchAdjustDifference,
      visibleBatchAdjust,
      visibleLoading,
      visibleImportFail,
      batchSubmit,
      upPercentage,
      failList,
      processNumbers,
      addSpace,
      clickDownTemplate,
      type,
      batchSplit,
      downLoadErrorFile,
      closeBatch,
      addPercent,
      times,
      exportDifference,
      regenerateByContractNum,
      batchRegenerateBtnRef,
      btnList,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="form" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>
    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex gap12">
          <v-btn v-auth="['ytAccountForm-generate-statement']" color="primary" @click="createFinal">
            生成结算单
          </v-btn>
          <batchRegenerateBtn ref="batchRegenerateBtnRef" v-auth="['ytAccountForm-regenerate']" :selected="selected" :outlined="true" @batchRegenerate="batchGenerate" @regenerateByContractNum="regenerateByContractNum" />
          <mainHeaderButtonList :list="btnList" />
        </div>
        <div class="num">
          <span class="items">CID:<span class="amount"> {{ processNumbers(cidForm.reportRevenue) }} </span></span>
          <span class="items">美国CID:<span class="amount"> {{ processNumbers(cidForm.reportRevenueUs) }} </span></span>
          <span class="items">新加坡CID:<span class="amount"> {{ processNumbers(cidForm.reportRevenueSg) }} </span></span>
          <span class="items">
            <TooltipBase title="结算单状态=已生成的实发，非全部数据的实发">
              <i class="iconfont icon-a-shuoming2x1 fs14"></i>
            </TooltipBase>
            实发 (￥):<span class="amount"> {{ processNumbers(cidForm.actualRmb) }} </span></span>
          <span class="items">结算单实发 (￥):<span class="amount"> {{ processNumbers(cidForm.settlementActualRmb) }} </span></span>
          <span class="items">实发 (￥) 差值:<span class="amount"> {{ processNumbers(cidForm.actualRmbDifference) }} </span></span>
        </div>
      </div>
      <div></div>
    </template>
    <SuperTable
      ref="table"
      :columns="tableColumn2"
      :data="tableData"
      :cell-style="cellStyle"
      :header-cell-style="headerCellStyle"
      :range="true"
      :loading="loading"
      @checkBoxChange="checkBoxChange"
      @selectAllEvent="selectAllEvent"
    >
      <template #channelType="{ row }">
        <span v-if="!row.channelSplitStatus">{{ filterMap('channelTypeOptions', row.channelType) }}</span>
        <span v-else> {{ filterMap('channelTypeOptions', row.channelType) }}(已拆) </span>
      </template>
      <template #channelName="{ row }">
        <div>{{ row.channelName }}</div>
        <div>
          {{ row.channelId }}
        </div>
      </template>
      <template #cms="{ row }">
        {{ filterMap('cmsOptions', row.cms) }}
      </template>
      <template #federalTax="{ row }">
        {{ addPercent(row.federalTax) }}
      </template>
      <template #singaporeTax="{ row }">
        {{ addPercent(row.singaporeTax) }}
      </template>
      <template #proportion="{ row }">
        {{ addPercent(row.proportion) }}
      </template>
      <template #serviceCharge="{ row }">
        {{ addPercent(row.serviceCharge) }}
      </template>
      <template #settlementCreatedStatus="{ row }">
        <xw-status-icon
          :color="statusPointColorMap[row.settlementCreatedStatus]"
          :text="filterMap('statusOptions', row.settlementCreatedStatus)"
        />
      </template>
      <template #actions="{ row }">
        <span
          v-if="
            [channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType) &&
              row.settlementCreatedStatus === 0
          "
          v-auth="['ytAccountForm-adjust-difference']"
          class="app-link"
          @click="adjustDifference(row)"
        >调差</span>
        <span
          v-if="[channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType)"
          v-auth="['ytAccountForm-subset']"
          class="app-link"
          @click="split(row)"
        >拆分子集</span>
        <span
          v-if="row.channelType === channelTypeMap.subset"
          v-auth="['ytAccountForm-del-subset']"
          class="app-link"
          @click="del(row.id)"
        >删除</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Adjust v-if="visibleAdjust" :form="revenueForm" @close="visibleAdjust = false" @refresh="init" />

    <Subset v-if="visibleSubset" :form="revenueForm.info" @close="visibleSubset = false" @refresh="init" />

    <createFinalDialog
      v-if="visibleCreateFinal"
      :form="{ data: selected, month: searchForm.month, platform: 'YT' }"
      @close="visibleCreateFinal = false"
      @refresh="init"
    />

    <NotListDialog v-if="visibleNotList" :form-data="times" @close="visibleNotList = false" @refresh="init" />

    <BatchImportDialog
      v-if="visibleBatchAdjust"
      :title="type"
      @close="visibleBatchAdjust = false"
      @submit="batchSubmit"
    >
      <div class="template">
        <div>
          1、下载 <span class="download-link" @click="clickDownTemplate">&lt;{{ type }}模板&gt;</span>；
        </div>
        <div>2、按规则填写后，上传文件。</div>
      </div>
    </BatchImportDialog>

    <ProgressLoading v-if="visibleLoading" :progress="upPercentage" @close="visibleLoading = false" />

    <ImportFailDialog
      v-if="visibleImportFail"
      :data="failList"
      @close="visibleImportFail = false"
      @closeBatch="closeBatch"
      @downLoadErrorFile="downLoadErrorFile"
    />
  </AppView>
</template>
<style scoped lang="scss">
.num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 20px;
  font-size: 14px;

  .amount{
      color: #FFA80F;
      padding: 0 10px 18px  0;
  }
}

.template {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  div {
    line-height: 30px;
  }
}
</style>
