import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"show-footer":"","title":"添加调整项","subtitle-text":("(" + (_vm.form.channelName) + " " + (_vm.form.subsetName ? ("-" + (_vm.form.subsetName)) : '') + ")"),"width":"600px"},on:{"confirm":_vm.submit,"cancel":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VForm,{ref:"formRef"},[_c(VRow,{staticClass:"content"},[_c(VCol,{staticClass:"mt5",attrs:{"cols":"3"}},[_vm._v(" 调整币种 ")]),_c(VCol,{attrs:{"cols":"7"}},[_c('RadioGroup',{attrs:{"radio-list":_vm.radioList,"row":""},model:{value:(_vm.formData.adjustCurrency),callback:function ($$v) {_vm.$set(_vm.formData, "adjustCurrency", $$v)},expression:"formData.adjustCurrency"}})],1)],1),_c(VRow,{staticClass:"content"},[_c(VCol,{staticClass:"mt5",attrs:{"cols":"3"}},[_vm._v(" 调整金额 ")]),_c(VCol,{attrs:{"cols":"7"}},[_c(VTextField,{attrs:{"placeholder":"请输入","dense":"","outlined":"","rules":[_vm.required,_vm.numberValidator10],"maxlength":"12"},model:{value:(_vm.formData.adjustAmount),callback:function ($$v) {_vm.$set(_vm.formData, "adjustAmount", $$v)},expression:"formData.adjustAmount"}})],1)],1),_c(VRow,{staticClass:"content"},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" 调整备注 ")]),_c(VCol,{attrs:{"cols":"7"}},[_c(VTextarea,{attrs:{"placeholder":"请输入","dense":"","outlined":"","height":"100px","counter":"200","maxlength":"200","rules":[_vm.requiredNonSpace]},model:{value:(_vm.formData.adjustRemark),callback:function ($$v) {_vm.$set(_vm.formData, "adjustRemark", $$v)},expression:"formData.adjustRemark"}})],1)],1),_c(VRow,{staticClass:"content"},[_c(VCol,{staticClass:"mt5",attrs:{"cols":"3"}},[_vm._v(" 调整依据附件 ")]),_c(VCol,{attrs:{"cols":"7"}},[_c('Upload',{attrs:{"btn-config":{
            text: '上传文件',
            icon: 'icon-a-shangchuan2x',
          },"files-detail":[_vm.fileInfoList],"accept-type":'.doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png',"before-upload":_vm.beforeUpload,"multiple":true,"disabled":_vm.fileInfoList.length >= 5},on:{"change":_vm.handleFileChange}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}}),_c(VCol,_vm._l((_vm.fileInfoList),function(item,index){return _c('div',{key:item.filePath,staticClass:"fileItem"},[_c('div',{staticClass:"mr10"},[_vm._v(" "+_vm._s(item.fileName)+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c(VSwitch,{staticClass:"mt-0 mx-1 w110",attrs:{"inset":"","dense":"","label":("" + (item.isShow ? '展示' : '不展示')),"hide-details":"","true-value":1,"false-value":0},model:{value:(item.isShow),callback:function ($$v) {_vm.$set(item, "isShow", $$v)},expression:"item.isShow"}}),_c('i',{staticClass:"iconfont icon-a-shanchu2x fs13 pointer mt5",on:{"click":function($event){return _vm.delFile(index, item.fileName)}}})],1)])}),0)],1),_c('div',{staticClass:"remark font-weight-bold mt12"},[_vm._v(" 使用说明 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 1、补款请输入正数，扣款请输入负数；计算公式为："+_vm._s(_vm.formulaTextMap[_vm.formData.adjustCurrency])+" ")]),_c('div',{staticClass:"remark"},[_vm._v(" 2、附件需要展示给CP的，如：违约扣款依据截图，请勾选【展示】；不需要展示给CP的，如：钉钉审批流程截图，请勾选【不展示】 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 3、附件支持上传doc、docx、xls、xlsx、图片、pdf，单份大小小于5M ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }