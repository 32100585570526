<script>
import { addSpace } from '@/@core/utils'
import { computed } from '@vue/composition-api'
import { isFunction } from '@/@core/utils/is'
import { getUserData } from '@/auth/utils'

export default {
  name: 'ButtonList',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    maxBtnNum: {
      type: Number,
      default: 4,
    },
    dropdownLabel: {
      type: String,
      default: '更多',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // 权限
    const userData = getUserData()
    const userRoles = userData.role || []

    // 过滤出有权限的按钮
    const newList = computed(() => props.list.filter(item => (!item.auth ? true : item.auth.some(value => userRoles.includes(value))) && (isFunction(item.hidden) ? item.hidden(props.item) : !item.hidden)))
    function separatedArray() {
      if (newList.value.length > props.maxBtnNum) {
        return newList.value.slice(0, props.maxBtnNum - 1)
      }

      return newList.value.slice(0, props.maxBtnNum)
    }

    function remainArray() {
      if (newList.value.length > props.maxBtnNum) {
        return newList.value.slice(props.maxBtnNum - 1)
      }

      return []
    }

    // 移除事件
    function removeEvent(item) {
      return {
        click: item.click ? () => item.click(props.item) : (() => {}),
      }
    }

    console.log(separatedArray())

    return {
      separatedArray,
      remainArray,
      removeEvent,
      addSpace,
    }
  },
}
</script>
<template>
  <div class="menu-view gap12">
    <!-- 按钮 -->
    <template v-for="(items, index) in separatedArray()">
      <v-btn
        :key="index"
        v-auth="items.vAuth"
        :color="!items.outlined ? 'primary' : ''"
        :outlined="items.outlined"
        v-on="removeEvent(items)"
      >
        {{ addSpace(items.label ) }}
      </v-btn>
    </template>
    <v-menu
      v-if="remainArray().length"
      offset-y
      open-on-hover
    >
      <template #activator="{ on }">
        <v-btn outlined class="app-link menuButton" width="30" min-width="30" v-on="on">
          <v-icon
            size="17"
          >
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(items, index) in remainArray()"
          :key="index"
          v-auth="items.vAuth"
        >
          <v-list-item-title
            style="cursor: pointer;"
          >
            <v-btn
              :key="index"
              v-auth="items.vAuth"
              :color="!items.outlined ? 'primary' : ''"
              :outlined="items.outlined"
              class="app-link"
              v-on="removeEvent(items)"
            >
              {{ addSpace(items.label) }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped lang='scss'>
.menu-view {
  display: flex;
  // flex-wrap: wrap;
}
.menuButton {
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
}

::v-deep .v-list{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
