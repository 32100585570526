<template>
  <v-card class="p20">
    <div v-if="!showFullcalendar" class="fc-toolbar">
      <div class="fc-center">
        <i class="el-icon-arrow-left" @click="prev" />
        <div class="title">
          {{ title }}
        </div>
        <i class="el-icon-arrow-right" @click="next" />
      </div>
    </div>

    <div v-if="showFullcalendar" class="d-flex justify-center">
      加载数据中......
    </div>
    <FullCalendar v-else id="calendar" ref="fullCalendar" class="demo-app-calendar" :options="calendarOptions">
    </FullCalendar>
  </v-card>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

let clickCount = 0
let prev = '' // 上一次点击的dom节点
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      showFullcalendar: true,
      title: '',
      currentView: {},
      options: [
        { value: 'timeline', label: 'resource-timeline' },
        { value: 'dategrid', label: 'agenda' },
      ],
      type: 'dategrid',
      calendarOptions: {
        locale: 'zh-cn',
        timeZone: 'UTC',
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          // resourceTimelinePlugin,
          interactionPlugin,
        ],
        buttonText: {
          // 设置按钮
          today: '今天',
          month: '月',
          week: '周',
          dayGrid: '天',
        },
        initialView: 'dayGridMonth', // 设置默认显示月，可选周、日
        resourceAreaWidth: 200,
        contentHeight: 730,
        slotMinWidth: 70,
        resourceOrder: 'number',
        editable: false,
        dayMaxEvents: true, // allow "more" link when too many events
        eventDurationEditable: true, // 可以调整事件的时间
        selectable: false, // 日历格子可选择
        nowIndicator: true, // 现在的时间线显示
        eventDisplay: 'block', // 争对全天的情况下，以块状显示
        headerToolbar: false, // 隐藏头部的导航栏
        selectMirror: false,
        displayEventEnd: true, // like 08:00 - 13:00
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false, // 设置时间为24小时
        },
        events: [],
        eventColor: '#378006',
        allDayText: '全天',
        dateClick: this.handleDateClick, // 点击日程事件
        eventClick: this.handleEventClick, // 点击日历中的某一日程
        select: this.handleDateSelect, // 监听用户选择的时间段，
        eventDrop: this.handleEventDrop, // 监听事件被拖动的操作
        eventResize: this.handleEventResize, // 监听事件调整大小的操作
        resourceAreaHeaderContent: 'Rooms',
        resources: [
          {
            id: '111',
            title: 'asas',
            number: 1,
          },
        ],
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        resourceLabelContent(arg) {
          return {
            html: `<div>id: ${arg.resource.id}</div><div>title: ${arg.resource.title}</div>`,
          }
        },
        views: {
          customTimeLineWeek: {
            type: 'resourceTimeline',
            duration: { weeks: 1 },
            slotDuration: { days: 1 },
            buttonText: 'Custom Week',
            slotLabelFormat: {
              weekday: 'long',
              // month: 'numeric',
              // day: 'numeric',
              omitCommas: true,
            },
          },
          customTimeLineMonth: {
            type: 'resourceTimeline',
            duration: { month: 1 },
            slotLabelFormat: {
              // month: 'numeric',
              day: 'numeric',
              // omitCommas: true,
            },
          },
          customGridWeek: {
            type: 'timeGridWeek',
            dayHeaderFormat: {
              weekday: 'long',
            },
            slotLabelFormat: {
              // 左侧时间格式
              hour: '2-digit',
              minute: '2-digit',
              meridiem: 'lowercase',
              hour12: false, // false设置时间为24小时
            },
          },
        },
        // 切换视图调用的方法
        datesSet() {},
      },
      calendarApi: null,
      dayDate: '',
      dialogFormVisible: false,
      form: {
        content: '',
      },
      selectInfo: {},
      currentMonth: '06',
      currentYear: '2025',
      monthEvent: [
        {
          id: '1',
          title: 'YT：月初API频道级',
          start: '2024-月份-04',
          end: '2024-月份-04',
          allDay: true,
          color: '#FB9716',
        },
        {
          id: '2',
          title: 'YT：暂估表',
          start: '2024-月份-05',
          end: '2024-月份-05',
          allDay: true,
          color: '#FB9716',
        },
        {
          id: '3',
          title: 'YT：月初视频级',
          start: '2024-月份-05',
          end: '2024-月份-05',
          allDay: true,
          color: '#00B09B ',
        },
        {
          id: '4',
          title: 'FB：暂估表',
          start: '2024-月份-06',
          end: '2024-月份-06',
          allDay: true,
          color: '#2CA0F8   ',
        },
        {
          id: '5',
          title: 'FB：INVOICE',
          start: '2024-月份-16',
          end: '2024-月份-16',
          allDay: true,
          color: '#2CA0F8',
        },
        {
          id: '6',
          title: 'FB：到账校验表INVOICE',
          start: '2024-月份-16',
          end: '2024-月份-16',
          allDay: true,
          color: '#00B09B ',
        },
        {
          id: '7',
          title: 'YT：财务报告频道级',
          start: '2024-月份-20',
          end: '2024-月份-20',
          allDay: true,
          color: '#FB9716 ',
        },
        {
          id: '8',
          title: 'YT：月末API频道级',
          start: '2024-月份-26',
          end: '2024-月份-26',
          allDay: true,
          color: '#FB9716 ',
        },
        {
          id: '9',
          title: 'YT：冲销表',
          start: '2024-月份-28',
          end: '2024-月份-28',
          allDay: true,
          color: '#FB9716',
        },
        {
          id: '10',
          title: 'FB：REMITTANCE',
          start: '2024-月份-28',
          end: '2024-月份-28',
          allDay: true,
          color: '#2CA0F8',
        },
        {
          id: '11',
          title: 'FB：到账校验表REMITTANCE',
          start: '2024-月份-28',
          end: '2024-月份-28',
          allDay: true,
          color: '#00B09B',
        },
      ],
    }
  },
  watch: {
    // 切换视图显示不同的事件
    'calendarApi.view.type': function () {
      this.getData()
    },
  },
  mounted() {
    setTimeout(() => {
      this.showFullcalendar = false
      this.$nextTick(() => {
        this.calendarApi = this.$refs.fullCalendar.getApi()
        this.title = this.calendarApi.view.title
        this.getData()
      })
    }, 1000)
  },
  methods: {
    // 监听用户选择的时间段，当用户选择了一段时间后会触发该回调，可以在这里处理创建新的日程。
    handleDateSelect(selectInfo) {
      console.log('selectInfo: ', selectInfo)
      this.selectInfo = selectInfo
      this.form.content = ''
      // 用户选择了一个日期范围时触发
      this.dialogFormVisible = true
    },
    // 用户拖动移动事件时触发
    handleEventDrop(dropInfo) {
      console.log('dropInfo: ', dropInfo)
      const updatedEvent = { ...dropInfo.event }
      updatedEvent.start = dropInfo.revertDuration ? dropInfo.oldEvent.start : dropInfo.event.start
      updatedEvent.end = dropInfo.event.end

      // 更新服务器上的事件或者重新排序你的事件数组
      // 示例：this.updateEventOnServer(updatedEvent);

      // 如果是在内存中维护事件，则更新本地数据
      const index = this.events.findIndex(e => e.id === updatedEvent.id)
      if (index !== -1) {
        this.events.splice(index, 1, updatedEvent)
      }
    },
    // 用户调整事件长度时触发
    handleEventResize(resizeInfo) {
      console.log('resizeInfo: ', resizeInfo)
      const updatedEvent = { ...resizeInfo.event }
      updatedEvent.end = resizeInfo.event.end

      // 同样更新服务器或本地数据
      // 示例：this.updateEventOnServer(updatedEvent);

      const index = this.events.findIndex(e => e.id === updatedEvent.id)
      if (index !== -1) {
        this.events.splice(index, 1, updatedEvent)
      }
    },
    getData() {
      setTimeout(() => {
        this.currentMonth = this.formattedMonth(this.calendarApi.view.currentStart)
        this.currentYear = this.formattedYear(this.calendarApi.view.currentStart)
        this.calendarOptions.events = this.calendarApi.view.type === 'dayGridMonth' ? this.getMonthEvent(this.monthEvent, this.currentMonth, this.currentYear) : this.weekEvent
      }, 200)
    },
    // 点击更多
    more(e) {
      console.log('more ', e)
    },
    // 确认弹框按钮
    navConfirm() {
      this.dialogFormVisible = false
      if (this.form.content) {
        this.calendarOptions.events.push({
          title: this.form.content,
          start: this.selectInfo.startStr,
          end: this.selectInfo.endStr,
        })
        // 更新日历视图以显示新添加的事件
        this.$refs.fullCalendar.getApi().addEvent({
          // 等同于 this.calendarApi.addEvent
          title: this.form.content,
          start: this.selectInfo.startStr,
          end: this.selectInfo.endStr,
        })
      }
    },
    // 增加事件
    addEvent() {
      this.form.content = ''
      this.dialogFormVisible = true
      // this.monthEvent
    },
    // 点击日历中的某一日程
    handleEventClick(clickInfo) {
      console.log('clickInfo:', clickInfo)
      // 用户点击事件时触发，用于编辑或删除事件
      const { event } = clickInfo
      console.log('Clicked on:', event.title)
      // 这里可以弹出模态框进行编辑或调用删除函数等操作
    },
    // 单击事件(日历中的某一天)
    handleDateClick(e) {
      this.dayDate = e.dateStr
      if (e.dateStr !== prev) {
        clickCount = 0
      }
      clickCount += 1
      prev = e.dateStr
      setTimeout(() => {
        if (clickCount === 2) {
          console.log('db click')
        } else if (clickCount === 1) {
          console.log('one click')
        }
        clickCount = 0
      }, 300)
    },
    // 切换
    prev() {
      this.calendarApi.prev()
      this.title = this.calendarApi.view.title
      this.getData()
    },
    next() {
      this.calendarApi.next()
      this.title = this.calendarApi.view.title
      this.getData()
    },
    // 今天
    // today(date, jsEvent, view) {
    //   // if (this.type === "timeline") {
    //   //   this.calendarApi.changeView("customTimeLineWeek");
    //   // } else {
    //   //   this.calendarApi.changeView("customGridWeek");
    //   // }
    //   this.calendarApi.today()
    //   this.title = this.calendarApi.view.title

    //   this.calendarApi.changeView('timeGridDay')
    //   // this.calendarApi.today();
    //   // this.title = this.calendarApi.view.title;
    // },
    // 月
    month() {
      if (this.type === 'timeline') {
        this.calendarApi.changeView('customTimeLineMonth')
      } else {
        this.calendarApi.changeView('dayGridMonth')
      }
      this.calendarApi.today()
      this.title = this.calendarApi.view.title
    },
    // 周
    // week() {
    //   if (this.type === 'timeline') {
    //     this.calendarApi.changeView('customTimeLineWeek')
    //   } else {
    //     this.calendarApi.changeView('customGridWeek')
    //   }
    //   this.calendarApi.today()
    //   this.title = this.calendarApi.view.title
    // },
    // // 天
    // day() {
    //   this.calendarApi.today()
    //   this.title = this.calendarApi.view.title
    // },
    // 查询
    search() {
      this.calendarApi.changeView('dayGrid', {
        start: '2022-07-07',
        end: '2022-07-09',
      })
    },
    // 选择时间线、日程
    handleType() {
      if (this.type === 'timeline') {
        this.calendarApi.changeView('customTimeLineMonth')
        this.calendarOptions.slotLabelFormat = null
      } else {
        this.calendarApi.changeView('dayGridMonth')
      }
    },

    // 获取当前月份
    formattedMonth(date) {
      const dateObject = new Date(date)
      const month = (`0${dateObject.getMonth() + 1}`).slice(-2) // 补零操作

      return `${month}`
    },
    // 获取当前年份
    formattedYear(date) {
      return new Date(date).getFullYear()
    },

    // 根据当前月份获取日程
    getMonthEvent(events, month, year) {
      return events.map(event => ({
        ...event,
        start: event.start.replace('月份', month).replace('2024', year),
        end: event.end.replace('月份', month).replace('2024', year),
      }))
    },
  },
}
</script>
<style scoped lang="scss">
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc {
  margin: 0 auto;
}
.fc-toolbar {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  /* flex: 1; */
  justify-content: start;
  align-items: center;
}
.fc-center {
  display: flex;
  /* align-content: center; */
  align-items: center;

}
.fc-center .title {
  font-size: 16px;
  padding: 0 15px;
  font-weight: 700;
}

::v-deep .fc-scrollgrid-sync-inner a{
  color: rgba(0, 0, 0, 0.8) !important;
}
::v-deep .fc-daygrid-day-top .fc-daygrid-day-number{
  color: rgba(0, 0, 0, 0.8) !important;
}
::v-deep .fc .fc-daygrid-day.fc-day-today{
  background-color: #f5f5f5;
}

::v-deep .fc-h-event .fc-event-main-frame{
  padding-left: 10px;
}
</style>
