<script>
import Upload from '@/components/upload/Upload.vue'
import { ref, reactive } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { notColumns } from '../tabCols'

export default {
  name: 'BatchAdjustDialog',
  components: { Dialog, Upload },
  props: {
    title: {
      type: String,
      default: '批量操作',
    },
  },
  setup(props, { emit }) {
    const show = ref(true)

    const { message } = useMessage()
    const fileKey = reactive({})
    const fileInfoList = ref([
    ])

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const beforeUpload = file => {
      const chunkSize = 1024 * 1024 * 5
      const { size } = file

      if (size > chunkSize) {
        message.warning('文件大小超过5MB')

        return false
      }

      return true
    }

    const handleFileChange = files => {
      const result = files.map(file => ({
        fileName: file.name,
        fileSize: file.size,
        filePath: file.key,
        bucketName: file.bucket,
        src: file.filePath,
      }))
      fileInfoList.value = result
    }

    const delFile = (index, name) => {
      fileInfoList.value.splice(index, 1)
      message.success(`${name}已删除`)
    }

    const submit = () => {
      if (!fileInfoList.value.length) return message.warning('请上传文件')
      const key = fileInfoList.value[0].filePath
      emit('submit', key)
    }

    return {
      notColumns,
      handleFileChange,
      show,
      close,
      submit,
      fileInfoList,
      fileKey,
      delFile,
      beforeUpload,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="title"
    width="500px"
    show-footer
    @cancel="close"
    @confirm="submit"
  >
    <slot />

    <div class="mt10">
      <Upload
        :btn-config="{
          text: '上传文件',
          icon: 'icon-a-shangchuan2x',
        }"
        :files-detail="[fileInfoList]"
        :accept-type="'.xls,.xlsx'"
        :before-upload="beforeUpload"
        @change="handleFileChange"
      />
    </div>
    <div class="mt10">
      <div v-for="(item, index) in fileInfoList" :key="item.filePath" class="fileItem">
        <div>{{ item.fileName }}</div>
        <i class="iconfont icon-a-shanchu2x fs1 pointer mr5" @click="delFile(index, item.fileName)"></i>
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.content{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
}
.fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7f8;
    padding: 6px 12px;
    margin-bottom: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    div{
      word-break: break-all;
    }
    &:last-child{
      margin-bottom:0;
    }
  }

::v-deep .template{
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  div{
    line-height: 30px;
  }
}
</style>
