<script>
import moment from 'moment'
import { numberValidator4, numberValidator, required } from '@/@core/utils/validation'
import FormInput from '@/components/FormInput/index.vue'
import { asynchronousReturn } from '@/@core/utils'
import { ref, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { rateEdit } from '../service'

export default {
  name: 'AddOrEdit',
  components: { Dialog, FormInput },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()

    const formData = ref({
      month: moment().format('yyyy-MM'),
      currency: 'USD',
      exchangeRate: undefined,
    })

    if (props.row.id) {
      formData.value = props.row
    }

    const { message } = useMessage()

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const searchConfig = computed(() => [
      {
        el: 'DatePickers', props: 'month', label: '月份：', type: 'month', min: '2024-01', max: '2099-12', disabled: !!props.row.id,
      },
      {
        el: 'RadioGroup', props: 'currency', label: '币种：', radioList: [{ label: '美元', value: 'USD' }],
      },
      {
        el: 'VTextField', props: 'exchangeRate', label: '汇率：', maxlength: 8, rules: [required, numberValidator4],
      },
    ])

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        rateEdit({ ...formData.value, id: props.row.id }).then(({ data }) => {
          if (data.status === 200) message.success('提交成功')
          emit('refresh')
          close()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    return {
      show,
      close,
      formRef,
      formData,
      submit,
      numberValidator,
      required,
      searchConfig,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="`${row.id ? '编辑' : '新增'}汇率`"
    width="500px"
    show-footer
    confirm-btn-text="提交"
    @cancel="close"
    @confirm="submit"
  >
    <v-form ref="formRef">
      <FormInput :columns="searchConfig" :value="formData"></FormInput>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>
</style>
