import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppView',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex-vertical",staticStyle:{"width":"100%"}},[_c('SearchForm',{ref:"form",attrs:{"columns":_vm.searchConfig,"value":_vm.searchForm,"search":_vm.search,"reset":_vm.reset}})],1)]},proxy:true},{key:"main-header",fn:function(){return [_c('batchRegenerateBtn',{directives:[{name:"auth",rawName:"v-auth",value:(['ytAccountForm-batch-rebuild']),expression:"['ytAccountForm-batch-rebuild']"}],ref:"batchRegenerateBtnRef",attrs:{"selected":_vm.selected},on:{"batchRegenerate":_vm.batchRegenerate,"regenerateByContractNum":_vm.regenerateByContractNum}}),_c(VBtn,{directives:[{name:"auth",rawName:"v-auth",value:(['ytAccountForm-batch-split-subset']),expression:"['ytAccountForm-batch-split-subset']"}],staticClass:"app-link",attrs:{"outlined":""},on:{"click":_vm.batchSplit}},[_vm._v(" "+_vm._s(_vm.addSpace('批量拆分子集'))+" ")]),_c(VBtn,{directives:[{name:"auth",rawName:"v-auth",value:(['ytAccountForm-estimate-export']),expression:"['ytAccountForm-estimate-export']"}],staticClass:"app-link",attrs:{"outlined":""},on:{"click":_vm.exportExcel}},[_vm._v(" "+_vm._s(_vm.addSpace('导出'))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"page-size":_vm.page.pageSize,"page-index":_vm.page.page,"total":_vm.page.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[_c('SuperTable',{ref:"table",attrs:{"columns":_vm.tableColumn1,"data":_vm.tableData,"loading":_vm.loading},on:{"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"channelName",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.channelName))]),_c('div',[_vm._v(_vm._s(row.channelId))])]}},{key:"cms",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.filterMap('cmsOptions', row.cms))+" ")]}},{key:"channelType",fn:function(ref){
var row = ref.row;
return [(!row.channelSplitStatus)?_c('span',[_vm._v(_vm._s(_vm.filterMap('channelTypeOptions', row.channelType)))]):_c('span',[_vm._v(" "+_vm._s(_vm.filterMap('channelTypeOptions', row.channelType))+"(已拆) ")])]}},{key:"federalTax",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.addPercent(row.federalTax))+" ")]}},{key:"singaporeTax",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.addPercent(row.singaporeTax))+" ")]}},{key:"proportion",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.addPercent(row.proportion))+" ")]}},{key:"serviceCharge",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.addPercent(row.serviceCharge))+" ")]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.channelType === _vm.channelTypeMap.subset)?_c('span',{directives:[{name:"auth",rawName:"v-auth",value:(['ytAccountForm-del-subset']),expression:"['ytAccountForm-del-subset']"}],staticClass:"app-link",on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")]):_vm._e(),([_vm.channelTypeMap.singleOpen, _vm.channelTypeMap.compilation].includes(row.channelType))?_c('span',{directives:[{name:"auth",rawName:"v-auth",value:(['ytAccountForm-subset']),expression:"['ytAccountForm-subset']"}],staticClass:"app-link",on:{"click":function($event){return _vm.split(row)}}},[_vm._v("拆分子集")]):_vm._e()]}}])}),(_vm.visibleSubset)?_c('Subset',{attrs:{"form":_vm.revenueForm},on:{"close":function($event){_vm.visibleSubset = false},"refresh":_vm.init}}):_vm._e(),(_vm.visibleLoading)?_c('ProgressLoading',{attrs:{"progress":_vm.upPercentage},on:{"close":function($event){_vm.visibleLoading = false}}}):_vm._e(),(_vm.visibleBatchSplit)?_c('BatchImportDialog',{attrs:{"title":"批量拆分子集"},on:{"close":function($event){_vm.visibleBatchSplit = false},"submit":_vm.batchSubmit}},[_c('div',{staticClass:"template"},[_c('div',[_vm._v(" 1、下载 "),_c('span',{staticClass:"download-link",on:{"click":_vm.clickDownTemplate}},[_vm._v("<批量拆分子集模板>")]),_vm._v("； ")]),_c('div',[_vm._v("2、按规则填写后，上传文件。")])])]):_vm._e(),(_vm.visibleImportFail)?_c('ImportFailDialog',{attrs:{"data":_vm.failList},on:{"close":function($event){_vm.visibleImportFail = false},"closeBatch":_vm.closeBatch,"downLoadErrorFile":_vm.downLoadErrorFile}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }