<script>
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import ButtonList from '@/components/ButtonList/index.vue'
import { uploadStatusColorMap } from '@/@core/utils/colorMap'
import { formatNumber, addSpace } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { ref, reactive, onMounted, nextTick, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import AddChannel from './AddChannel.vue'
import mapEnum, { filterMap, apiMap } from '../mapEnum'
import AddGenerateReport from './AddGenerateReport.vue'
import { remove } from '../service'

export default {
  name: 'CommonTable',
  components: { AppView, SuperTable, Pagination, AddGenerateReport, SearchForm, ButtonList, AddChannel, XwStatusIcon },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: 'report',
    },
    label: {
      type: String,
      default: '报表生成管理',
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const loading = ref(false)
    const { message, MessageBox } = useMessage()
    const formRef = ref()
    const searchForm = computed(() => props.formData).value
    const visibleAddGenerateReport = ref(false)
    const visibleAddChannel = ref(false)
    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const searchConfig = ref([
      {
        el: 'VSelect',
        props: 'platform',
        label: '平台',
        items: mapEnum.platformType,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VTextField',
        props: 'channelName',
        label: '频道/主页名称',
      },
      {
        el: 'VTextField',
        props: 'channelId',
        label: '频道/主页ID',
      },
    ])

    const tableData = ref([])
    const init = () => {
      loading.value = true
      apiMap[props.tab]({ ...page, total: undefined, ...searchForm }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        init()
      })
    }

    const addGenerateReport = () => {
      visibleAddGenerateReport.value = true
    }

    const addChannel = () => {
      visibleAddChannel.value = true
    }

    const del = row => {
      MessageBox({
        message: '你确定删除吗？',
        type: 'error',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确认'),
      }).then(() => {
        remove({ settlementNoId: row.id }).then(({ data }) => {
          if (data.status === 200) message.success('删除成功')
          init()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    // 按钮
    const tableBtnList = reactive([
      {
        label: '删除',
        click: del,
        hidden: props.tab !== 'noSettlement',
        vAuth: ['reportGeneration-generate-del'],
      },
    ])

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const search = () => {
      page.page = 1
      init()
    }

    onMounted(() => {
      init()
    })

    return {
      loading,
      formRef,
      page,
      tableData,
      reset,
      search,
      init,
      handleCurrentChange,
      handleSizeChange,
      addGenerateReport,
      addChannel,
      addSpace,
      visibleAddGenerateReport,
      visibleAddChannel,
      uploadStatusColorMap,
      filterMap,
      searchForm,
      searchConfig,
      tableBtnList,
    }
  },
}
</script>
<template>
  <AppView class="px-5 pt-5">
    <template v-if="tab === 'noSettlement'" #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="formRef" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>

    <template #main-header>
      <v-btn v-if="tab === 'report'" v-auth="['reportGeneration-generate-report']" color="primary" class="app-link" @click="addGenerateReport">
        {{ addSpace('生成报表') }}
      </v-btn>
      <v-btn v-if="tab === 'noSettlement'" v-auth="['reportGeneration-generate-save']" color="primary" class="app-link" @click="addChannel">
        {{ addSpace('添加频道/主页') }}
      </v-btn>
      <span v-if="tab === 'noSettlement'" class="app-link remark">注：在此名单中的频道/主页，在生成结算单时，即使有合约也不产生结算</span>
    </template>
    <SuperTable
      :columns="header"
      :data="tableData"
      :loading="loading"
    >
      <template #status="{row}">
        <xw-status-icon :color="uploadStatusColorMap[row.status]" :text="filterMap('statusOptions',row.status)" />
      </template>
      <template #actions="{row}">
        <ButtonList :list="tableBtnList" :item="row" />
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <AddGenerateReport v-if="visibleAddGenerateReport" @close="visibleAddGenerateReport = false" @refresh="init" />

    <AddChannel v-if="visibleAddChannel" @close="visibleAddChannel = false" @refresh="init" />
  </AppView>
</template>
<style scoped lang='scss'>
</style>
