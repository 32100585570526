import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-view gap12"},[_vm._l((_vm.separatedArray()),function(items,index){return [_c(VBtn,_vm._g({directives:[{name:"auth",rawName:"v-auth",value:(items.vAuth),expression:"items.vAuth"}],key:index,attrs:{"color":!items.outlined ? 'primary' : '',"outlined":items.outlined}},_vm.removeEvent(items)),[_vm._v(" "+_vm._s(_vm.addSpace(items.label ))+" ")])]}),(_vm.remainArray().length)?_c(VMenu,{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"app-link menuButton",attrs:{"outlined":"","width":"30","min-width":"30"}},on),[_c(VIcon,{attrs:{"size":"17"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,false,2235925686)},[_c(VList,_vm._l((_vm.remainArray()),function(items,index){return _c(VListItem,{directives:[{name:"auth",rawName:"v-auth",value:(items.vAuth),expression:"items.vAuth"}],key:index},[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"}},[_c(VBtn,_vm._g({directives:[{name:"auth",rawName:"v-auth",value:(items.vAuth),expression:"items.vAuth"}],key:index,staticClass:"app-link",attrs:{"color":!items.outlined ? 'primary' : '',"outlined":items.outlined}},_vm.removeEvent(items)),[_vm._v(" "+_vm._s(_vm.addSpace(items.label))+" ")])],1)],1)}),1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }