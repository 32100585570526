import { statementTypeOptions, checkStatusOptions, invoiceStatusOptions, paymentStatusOptionsStatement } from '@/@core/utils/options'

const mapEnum = {
  statementTypeOptions,
  checkStatusOptions,
  invoiceStatusOptions,
  paymentStatusOptions: paymentStatusOptionsStatement,
}

export default mapEnum

export const tabMap = {
  total: '全部',
  customerCheckFirst: '待客服审核',
  financeCheck: '待财务审核',
  customerCheckSecond: '待客服二审',
  pushed: '已推送',
  notSettled: '暂不结算',
}
