<script>
import DialogTable from '@/components/Dialog/DialogTable.vue'
import { onMounted, ref, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { failHeader } from '../tabCols'

export default {
  name: 'ImportFailDialog',
  components: { Dialog, DialogTable },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showRejectBtnText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const tableData = computed(() => props.data.map(item => {
      const { errMsg, channelIdError, cmsError, adjustError, channelIdIsExistError, cmsAndChannelIdError, channelSplitStatusError, settlementCreatedStatusError, cmsRevenueAdjustError, cmsRevenueUsAdjustError, cmsRevenueSgAdjustError, cmsRevenueAdjustRemarkError, dataRepeatError, cmsRevenueUsAdjustRemarkError, cmsRevenueSgAdjustRemarkError } = item

      return {
        rowNum: item.rowNum,
        reasonForFailure: `${errMsg || ''}${channelIdError || ''}${cmsError || ''}${adjustError || ''}${channelIdIsExistError || ''}${cmsAndChannelIdError || ''}${channelSplitStatusError || ''}${settlementCreatedStatusError || ''}${cmsRevenueAdjustError || ''}${cmsRevenueUsAdjustError || ''}${cmsRevenueSgAdjustError || ''}${cmsRevenueAdjustRemarkError || ''}${dataRepeatError || ''}${cmsRevenueUsAdjustRemarkError || ''}${cmsRevenueSgAdjustRemarkError || ''}`,
      }
    }))

    const init = () => {

    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      close()
      emit('closeBatch')
    }

    const downLoadErrorFile = () => {
      emit('downLoadErrorFile', tableData.value)
    }

    onMounted(() => {
      init()
    })

    return {
      show,
      close,
      submit,
      downLoadErrorFile,
      failHeader,
      tableData,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="导入失败"
    width="500px"
    show-footer
    :show-reject-btn-text="showRejectBtnText"
    :show-cancel-btn-text="false"
    confirm-btn-text="我知道了"
    reject-btn-text="下载文件"
    @cancel="close"
    @confirm="submit"
    @reject="downLoadErrorFile"
  >
    <div class="text">
      导入失败，请修改后重新导入
    </div>
    <DialogTable
      class="mt12"
      :columns="failHeader"
      :data="tableData"
      :item-height="50"
      :is-hover="false"
      max-height="550px"
      min-height="50px"
    >
      <template #rowNum="{row}">
        {{ `第${row.rowNum || 0}行` }}
      </template>
    </DialogTable>
  </Dialog>
</template>
<style scoped lang='scss'>
.dialog-body{
  font-size: 14px !important;
  .text{
    color: rgba(0,0,0,0.85);
  }
}

</style>
