export const currency = [
  { label: '人民币', value: 'CNY' },
  { label: '美元', value: 'USD' },
  { label: '欧元', value: 'EUR' },
  { label: '英镑', value: 'GBP' },
  { label: '日元', value: 'JPY' },
  { label: '港币', value: 'HKD' },
  { label: '澳元', value: 'AUD' },
]

export const tableColumn = [
  { text: '月份', value: 'month', minWidth: 180 },
  { text: '币种', value: 'currency', minWidth: 120, enum: currency },
  { text: '汇率', value: 'exchangeRate', minWidth: 180 },
  { text: '创建人', value: 'createdUser', minWidth: 120 },
  { text: '创建时间', value: 'createdAt', minWidth: 180 },
  { text: '操作', value: 'actions', minWidth: 160, fixed: 'right' },
]
