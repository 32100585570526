import { platformType, sendSmsStatusOptions } from '@/@core/utils/options'

import { getList, getSettlementNoList } from './service'

const mapEnum = {
  report: {
    YT: [
      { label: 'YT暂估表(老)', value: 'YT-estimate' },
      { label: 'YT暂估表(新)', value: 'YT-estimate-new' },
      { label: 'YT冲销表(老)', value: 'YT-reversal' },
      { label: 'YT冲销表(新)', value: 'YT-reversal-new' },
      { label: 'YT月初视频级', value: 'YT-monthApi' },
      { label: 'YT财务报告频道级', value: 'YT-monthReport' },
      { label: 'YT月初API频道级', value: 'YT-monthOriginalStart' },
      { label: 'YT月末API频道级', value: 'YT-monthOriginalEnd' },
    ],
    FB: [
      { label: 'FB暂估表', value: 'FB-estimate' },
      { label: 'FB INVOICE表', value: 'FB-invoice' },
      { label: 'FB REMITTANCE表', value: 'FB-remittance' },
      { label: 'FB到账校验表', value: 'FB-verification' },
    ],
  },
  statusOptions: [
    { label: '未生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '生成失败', value: 2 },
  ],
  platformType,
  sendSmsStatusOptions,
}

export default mapEnum

export function filterMap(key, val) {
  const arr = mapEnum[key] || []
  const data = arr.find(item => item.value === val)

  return (data && data.label) || '-'
}

export const apiMap = {
  report: getList,
  noSettlement: getSettlementNoList,
}
