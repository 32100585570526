<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { tabStatistics } from './service'
import { tableColumns1, tableColumns2, tableColumns3, tableColumns4 } from './tabCols'
import commonTable from './components/commonTable.vue'

export default {
  name: 'VATInvoice',
  components: { TabsPro, TabPanePro, commonTable },
  props: {},
  setup() {
    const componentRef = ref()
    const activeName = ref('待上传')
    const searchForm = reactive({
      time: [],
      cpName: undefined,
      payoutPeriod: [],
      type: '',
      paymentStatus: undefined,
      sendSmsStatus: undefined,
    })

    const tabTotal = ref([])

    const tabList = computed(() => [
      { label: '待上传', value: 3, element: 'commonTable', columns: tableColumns4, total: (tabTotal.value.find(item => item.tab === '3')?.count) || 0 },
      { label: '待审核', value: 0, element: 'commonTable', columns: tableColumns1, total: (tabTotal.value.find(item => item.tab === '0')?.count) || 0 },
      { label: '审核通过', value: 1, element: 'commonTable', columns: tableColumns2, total: (tabTotal.value.find(item => item.tab === '1')?.count) || 0 },
      { label: '已驳回', value: -1, element: 'commonTable', columns: tableColumns3, total: (tabTotal.value.find(item => item.tab === '-1')?.count) || 0 },
    ])

    const tabClick = val => {
      activeName.value = val.name
      const index = tabList.value.findIndex(item => item.label === val.name)
      componentRef.value[index].search()
    }

    const getTotal = formData => {
      tabStatistics({ ...formData }).then(({ data }) => {
        tabTotal.value = data.data
      })
    }

    onMounted(() => {
      getTotal()
    })

    const updateTotal = settlementTab => {
      getTotal(settlementTab)
    }

    return {
      activeName,
      componentRef,
      tabClick,
      tabList,
      searchForm,
      updateTotal,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro v-model="activeName" style="height: 100%" @tabClick="tabClick">
      <tab-pane-pro v-for="item in tabList" :key="item.label" :label="`${item.label}(${ item.total > 999 ? '999+' : item.total })`" :name="item.label">
        <component
          :is="item.element"
          ref="componentRef"
          :key="item.value"
          :header="item.columns"
          :form-data="searchForm"
          :tab="item.value"
          :label="item.label"
          :active-name="activeName"
          @updateTotal="updateTotal"
        >
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  height: calc(100% - 50px);
}

</style>
