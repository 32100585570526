import http from '@http'

/**
 * 报表生成记录管理
 * @param {*} params
 * @returns
 */
export function getList(params) {
  return http.get('/report/create/record/page', params)
}

/**
 * 查询当月报表是否已存在
 * @param {*} params
 * @returns
 */
export function isExist(data) {
  return http.post(`/report/create/record/exist?month=${data.month}&name=${data.name}`)
}

/**
 * 生成报表
 * @param {*} params
 * @returns
 */
export function createReport(data) {
  return http.post(`/report/create/record/create?month=${data.month}&name=${data.name}`)
}

/**
 * 不结算名单
 * @param {*} params
 * @returns
 */
export function getSettlementNoList(params) {
  return http.get('/settlementNo/queryPage', params)
}

/**
 * 删除不结算名单
 * @param {*} params
 * @returns
 */
export function remove(params) {
  return http.get('/settlementNo/removeById', params)
}

/**
 * 新增不结算名单
 * @param {*} params
 * @returns
 */
export function save(params) {
  return http.post('/settlementNo/save', params)
}

/**
 * 根据频道id 获取频道
 * @param {*} params
 * @returns
 */
export function domesticChannels(params) {
  return http.get('/settlementNo/domesticChannels/ams/yt', params)
}
